import { createStore } from 'redux';

const INITIAL_STATE = {
        aluno:{
            auth_token: "",
            inscricao_situacao: "",
            inscricao_numero_ref: "",
            inscricao_numero: "",
            inscricao_edital_ref: "",
            campus_ref: 0,
            campus_nome: "",
            nome: "",
            endereco:{
                cep: "",
                logradouro: "",
                numero: "",
                bairro: "",
                cidade: "",
                uf: "",
                localizacao: undefined,
            },
            curso:{
                nome: "",
                ano: "",
                turma: "",
            },
            conta:{
                tipo: undefined,
                banco: "",
                agencia: "",
                numero: "",
            }
        },
        edital_selecionado: undefined,
    };

function reducer(state = INITIAL_STATE, action) {

    switch (action.type){

        case 'AUTENTICA':
            return { ... state, aluno: action.aluno };
            break;
        case 'SELECIONA_EDITAL':
            return { ... state, edital_selecionado: action.edital_selecionado };
            break;
        case 'NOVO_RECURSO':
            return { ... state,
                edital_selecionado: action.edital_selecionado,
                aluno: {
                    ... state.aluno,
                    inscricao_numero_ref: action.inscricao_numero_ref,
                } };
            break;
        case 'LIMPA_INSCRICAO':
            return { ... state,
                aluno: {
                    ... state.aluno,
                    inscricao_situacao: undefined,
                    inscricao_numero: undefined,
                    inscricao_numero_ref: undefined,
                    inscricao_edital_ref: undefined,
                    inscricao_edital_numero: undefined,
                } };
            break;
        case 'FINALIZA_INSCRICAO':
            return { ... state,
                aluno: {
                    ... state.aluno,
                    inscricao_situacao: 1,
                } };
            break;
        case 'RECURSO_ENVIADO':
            return { ... state,
                aluno: {
                    ... state.aluno,
                    msg_sucesso: 'O recurso foi enviado com sucesso!',
                    inscricao_recurso: 'enviado',
                } };
            break;
        case 'ATUALIZA_DADOS':
            return { ... state,
                aluno: action.aluno };
            break;
        case 'LOGOFF':
            return INITIAL_STATE;
            break;
        default:
            return state;
    }

}

const saveState = (state) => {
    try {
        const serialisedState = JSON.stringify(state);

        window.sessionStorage.setItem('assistencia', serialisedState);
    } catch (err) {
    }
};

const loadState = () => {
    try {

        const serialisedState = window.sessionStorage.getItem('assistencia');

        if (!serialisedState) return undefined;

        return JSON.parse(serialisedState);
    } catch (err) {

        return undefined;
    }
};
const oldState = loadState();
const store = createStore(reducer, oldState);

store.subscribe(() => {
    saveState(store.getState());
});


export default store;