import React from 'react';

import {
  Layout,
  Space,
  Breadcrumb,
  Typography,
  Checkbox,
  Radio,
  Input,
  Button,
  message} from 'antd';
import InputMask from 'react-input-mask';


import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import windowSize from 'react-window-size';
import LayoutSite from '../../componentes/LayoutSite';

import axios from 'axios';

const { Content } = Layout;
const { Title, Text } = Typography;


class NovaInscricao extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      processando: false,
      messageError: [],
      auxilios_selecionados: [],
      endereco_localizacao: (props.aluno.endereco.localizacao !== undefined
        && props.aluno.endereco.localizacao !== null) ?
          props.aluno.endereco.localizacao : undefined,
      curso_ano: (props.aluno.curso.ano !== undefined
        && props.aluno.curso.ano !== null) ?
        props.aluno.curso.ano : "",
      trabalha: (props.aluno.trabalha !== undefined
        && props.aluno.trabalha !== null) ?
        props.aluno.trabalha : undefined,
      trabalho_descricao: (props.aluno.trabalho_descricao !== undefined
        && props.aluno.trabalho_descricao !== null) ?
        props.aluno.trabalho_descricao : "",
      trabalho_remuneracao: (props.aluno.trabalho_remuneracao !== undefined
        && props.aluno.trabalho_remuneracao !== null) ?
        props.aluno.trabalho_remuneracao : "",
      conta_tipo: (props.aluno.conta.tipo !== undefined
        && props.aluno.conta.tipo !== null) ?
        props.aluno.conta.tipo : undefined,
      conta_banco: (props.aluno.conta.banco !== undefined
        && props.aluno.conta.banco !== null) ?
        props.aluno.conta.banco : "",
      conta_agencia: (props.aluno.conta.agencia !== undefined
        && props.aluno.conta.agencia !== null) ?
        props.aluno.conta.agencia : "",
      conta_numero: (props.aluno.conta.numero !== undefined
        && props.aluno.conta.numero !== null) ?
        props.aluno.conta.numero : "",
      contato_celular: (props.aluno.celular !== undefined
        && props.aluno.celular !== null) ?
        props.aluno.celular : "",
      autorizado: (props.aluno.auth_token.length && props.edital_selecionado !== undefined),
    }

    this.onMarcaAuxilioChange = this.onMarcaAuxilioChange.bind(this);
    this.onLocalizacaoChange = this.onLocalizacaoChange.bind(this);
    this.onContaTipo = this.onContaTipo.bind(this);
    this.iniciaInscricaoClick = this.iniciaInscricaoClick.bind(this);
    this.onCursoAnoChange = this.onCursoAnoChange.bind(this);
    this.onTrabalhaRadioChange = this.onTrabalhaRadioChange.bind(this);
    this.onTrabalhoRendaChange = this.onTrabalhoRendaChange.bind(this);


    if (!this.state.autorizado)
      this.props.history.push('/');

  }

  componentWillUnmount(){
    this._mounted = false;
  }

  componentDidMount(){
    this._mounted = true;
  }

  onMarcaAuxilioChange(e, auxilio_ref){
    let auxilios_selecionados = this.state.auxilios_selecionados;

    if (e.target.checked)
      auxilios_selecionados.push(auxilio_ref);
    else
      auxilios_selecionados.splice(auxilios_selecionados.indexOf(auxilio_ref), 1);

    this.setState({
      auxilios_selecionados
    });
  }

  onLocalizacaoChange(e){
    this.setState({
      endereco_localizacao: e.target.value,
    })
  }

  onCursoAnoChange(e){
    const { value } = e.target;
    const reg = /^\d{1,4}$/i;
    if (reg.test(value) || value === '') {
      this.setState({
        curso_ano: value,
      })
    }
  }

  onTrabalhaRadioChange(e){
    const { value } = e.target;

    if (value === 'S')
      this.setState({
        trabalha: value,
      });
    else
      this.setState({
        trabalha: value,
        trabalho_descricao: "",
        trabalho_remuneracao: "",
      });
  }

  onTrabalhoRendaChange(e){
    const { value } = e.target;
    const reg = /^\d{1,5}(\,\d{0,2})?$/i;
    if (reg.test(value) || value === '') {
      this.setState({
        trabalho_remuneracao: value,
      })
    }
  }

  onContaTipo(e){
    let conta_banco = this.state.conta_banco;
    let conta_agencia = this.state.conta_agencia;
    let conta_numero = this.state.conta_numero;

    if (e.target.value === -1){
      conta_banco = undefined;
      conta_agencia = undefined;
      conta_numero = undefined;
    }
    this.setState({
      conta_tipo: e.target.value,
      conta_banco,
      conta_agencia,
      conta_numero,
    });
  }

  iniciaInscricaoClick(){
    const {auxilios_selecionados, endereco_localizacao, curso_ano,
      conta_tipo, conta_banco, conta_agencia, conta_numero,
      contato_celular, trabalha, trabalho_descricao,
      trabalho_remuneracao } = this.state;

    const { auth_token } = this.props.aluno;
    const { edital_selecionado } = this.props;

    let aluno = this.props.aluno;

    let messageError = [];

    if (!auxilios_selecionados.length)
      messageError.push('Escolha pelo menos um auxílio.');
    if (endereco_localizacao === undefined)
      messageError.push('A localização do endereço não foi selecionada.');
    if (!curso_ano.length)
      messageError.push('O ano do curso não foi preenchido.');
    else if (isNaN(curso_ano))
      messageError.push('O ano do curso deve ser um número inteiro.');

    if (trabalha === undefined)
      messageError.push('Informe se recebe alguma renda.');
    else if (trabalha === 'S'){
      if (!trabalho_descricao.length)
        messageError.push('A descrição da(s) renda(s) não foi preenchida.');
      if (!trabalho_remuneracao.length)
        messageError.push('O valor da(s) renda(s) não foi preenchido.');
    }

    if (conta_tipo === undefined)
      messageError.push('O tipo da conta não foi selecionado.');

    if (contato_celular.replace(/_|\(|\)|\-| */g,'').length < 11)
      messageError.push('O celular não foi preenchido.');

    if (messageError.length)
      this.setState({messageError});
    else
      this.setState({messageError, processando: true}, ()=>{
        //envia requisição para o webservice
        let qs = require('qs');

        const config = {
          "Content-Type": "application/x-www-form-urlencoded"
        }

        const requestBody = qs.stringify(
          { auth_token, auxilios_selecionados, endereco_localizacao, curso_ano,
            conta_tipo, conta_banco, conta_agencia, conta_numero,
            contato_celular, trabalha, trabalho_descricao, trabalho_remuneracao }
        );

        axios.post(process.env.REACT_APP_API_URL
          + 'novaInscricao',
          requestBody,
          config)
          .then(res => {
          if (res.data.sucesso){
            //dispatch para atualizar os dados do aluno
            //e os dados da inscrição
            aluno.endereco.localizacao = endereco_localizacao;
            aluno.curso.ano = curso_ano;
            aluno.conta.tipo = conta_tipo;
            aluno.conta.banco = conta_banco;
            aluno.conta.agencia = conta_agencia;
            aluno.conta.numero = conta_numero;
            aluno.celular = contato_celular;
            aluno.trabalha = trabalha;
            aluno.trabalho_descricao = trabalho_descricao;
            aluno.trabalho_remuneracao = trabalho_remuneracao;
            aluno.inscricao_numero = res.data.inscricao_numero;
            aluno.inscricao_numero_ref = res.data.inscricao_numero_ref;
            aluno.inscricao_edital_ref = res.data.inscricao_edital_ref;
            aluno.inscricao_edital_data_limite = res.data.inscricao_edital_data_limite;
            aluno.inscricao_edital_numero = edital_selecionado.numero;
            aluno.inscricao_situacao = 0;

            this.props.dispatch({
              type: 'ATUALIZA_DADOS',
              aluno});

            //redireciona para a rota de inscrição atual
            this.props.history.push('/inscricao');

          } else {
            res.data.message.map(message=>messageError.push(message));

          }
          })
          .catch((error) => {
            messageError.push("Verifique sua Internet e tente novamente.")
            this.setState({
              processando: false,
              messageError,
            });
          })
          .finally(()=>{
            if (this._mounted)
              this.setState({
                processando: false,
                messageError});
          });
      });

  }

  render() {
    const { processando, messageError, autorizado, auxilios_selecionados, endereco_localizacao,
      curso_ano, contato_celular,
      conta_tipo, conta_banco, conta_agencia, conta_numero, trabalha,
      trabalho_descricao, trabalho_remuneracao  } = this.state;
    const { aluno, edital_selecionado } = this.props;
    const windowWidth = this.props.windowWidth;
    const { onMarcaAuxilioChange, onLocalizacaoChange, onContaTipo,
      iniciaInscricaoClick, onCursoAnoChange, onTrabalhaRadioChange,
      onTrabalhoRendaChange } = this;


    if (!autorizado)
      return null;

    return (

        <LayoutSite>

            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Assistência Estudantil</Breadcrumb.Item>
              <Breadcrumb.Item>Inscrição</Breadcrumb.Item>
            </Breadcrumb>

            <Layout className="site-layout-background"
              style={{
                marginTop: "24px",
                padding: '12px 0' }}>
                  <Content
                    style={{ padding: '0 12px', minHeight: 280 }}>
                      <Space
                        direction="vertical"
                        style={{width: "100%"}}>
                          <Button
                                type="link"
                                onClick={()=>this.props.history.push("/")}
                                style={{padding: "0"}}>Início</Button>
                          <Title level={3}>Inscrição - Edital {edital_selecionado.numero}</Title>

                          <div>
                            <Space
                              direction="vertical"
                              style={{width: "100%"}}>

                              <Text>Preencha abaixo todos os campos obrigatórios (*) para iniciar a inscrição. </Text>
                              <Text>* Marque na(s) caixa(s) abaixo o(s) auxílio(s) que deseja concorrer:</Text>

                              {(windowWidth < 640) ? (
                                <table style={{width: "100%"}}>
                                    <thead className="ant-table-thead">
                                        <tr>
                                            <th className="ant-table-cell">Descrição</th>
                                        </tr>
                                    </thead>
                                    <tbody className="ant-table-tbody">
                                        {edital_selecionado.auxilios.map(rowData =>
                                        <tr
                                            key={rowData.key}
                                            className="ant-table-row ant-table-row-level-0">
                                            <td className="ant-table-cell">
                                                <Space
                                                    direction="vertical"
                                                    width="100%">
                                                    <Checkbox
                                                          checked={(auxilios_selecionados.indexOf(rowData.key) >= 0)}
                                                          onChange={(e)=>onMarcaAuxilioChange(e, rowData.key)}>
                                                            <Text strong style={{display: "block"}}>{rowData.nome}</Text>
                                                            <Text>{rowData.descricao}</Text>
                                                    </Checkbox>
                                                    <Space
                                                        direction="horizontal"
                                                        width="100%">
                                                        <Text strong>Vagas:</Text>
                                                        <Text>{rowData.vagas}</Text>
                                                        <Text strong>Valor:</Text>
                                                        <Text>{rowData.valor}</Text>
                                                    </Space>
                                                </Space>
                                            </td>
                                        </tr>
                                        )}
                                    </tbody>
                                </table>
                              ):(
                                <table style={{width: "100%"}}>
                                    <thead className="ant-table-thead">
                                        <tr>
                                            <th className="ant-table-cell"></th>
                                            <th className="ant-table-cell">Descrição</th>
                                            <th className="ant-table-cell">Vagas</th>
                                            <th className="ant-table-cell">Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody className="ant-table-tbody">
                                        {edital_selecionado.auxilios.map(rowData =>
                                        <tr
                                            key={rowData.key}
                                            className="ant-table-row ant-table-row-level-0">
                                            <td className="ant-table-cell">
                                              <Checkbox
                                                checked={(auxilios_selecionados.indexOf(rowData.key) >= 0)}
                                                onChange={(e)=>onMarcaAuxilioChange(e, rowData.key)} />
                                            </td>
                                            <td className="ant-table-cell">
                                              <Text strong style={{display: "block"}}>{rowData.nome}</Text>
                                              <Text>{rowData.descricao}</Text>
                                            </td>
                                            <td className="ant-table-cell">{rowData.vagas}</td>
                                            <td className="ant-table-cell">{rowData.valor}</td>
                                        </tr>
                                        )}
                                    </tbody>
                                </table>
                              )}

                              <Text strong>Dados cadastrais</Text>

                              <Text>Verifique se os dados de nome, sexo e endereço estão corretos. Caso estejam incorretos, procure a secretaria do seu Campus para atualização dos seus dados cadastrais no SUAP.</Text>

                              <Space
                                direction="horizontal">
                                  <div>
                                      <Text style={{display: "block"}}>Nome:</Text>
                                      <Text strong>{aluno.nome}</Text>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>Data Nascimento:</Text>
                                      <Text strong>{aluno.data_nascimento}</Text>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>Sexo:</Text>
                                      <Text strong>{aluno.sexo}</Text>
                                  </div>
                              </Space>

                              <Text underline>Endereço</Text>

                              <Space
                                direction="horizontal">
                                  <div>
                                      <Text style={{display: "block"}}>CEP:</Text>
                                      <Text strong>{aluno.endereco.cep}</Text>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>Logradouro:</Text>
                                      <Text strong>{aluno.endereco.logradouro}</Text>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>Número:</Text>
                                      <Text strong>{aluno.endereco.numero}</Text>
                                  </div>
                              </Space>
                              <Space
                                direction="horizontal">
                                  <div>
                                      <Text style={{display: "block"}}>Bairro:</Text>
                                      <Text strong>{aluno.endereco.bairro}</Text>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>Cidade:</Text>
                                      <Text strong>{aluno.endereco.cidade}</Text>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>UF:</Text>
                                      <Text strong>{aluno.endereco.uf}</Text>
                                  </div>
                              </Space>

                              <Text>* Localização:</Text>
                              <Radio.Group
                                onChange={onLocalizacaoChange}
                                value={endereco_localizacao}>
                                <Radio value={0}>Urbano</Radio>
                                <Radio value={1}>Rural</Radio>
                              </Radio.Group>

                              <Text underline>Curso</Text>

                              {(windowWidth < 640)? (
                                <Space
                                  direction="vertical">
                                    <Space
                                      direction="horizontal">
                                        <div style={{width: "100%"}}>
                                          <Text style={{display: "block"}}>Curso:</Text>
                                          <Text strong>{aluno.curso.nome}</Text>
                                        </div>
                                    </Space>
                                    <Space
                                      direction="horizontal">
                                      <div>
                                        <Text style={{display: "block"}}>* Ano de ingresso:</Text>
                                        <Input
                                          value={curso_ano}
                                          onChange={onCursoAnoChange}
                                          placeholder="Ex. 2019"/>
                                      </div>
                                    </Space>
                                </Space>
                              ):(
                                <Space
                                  direction="horizontal">
                                  <div>
                                    <Text style={{display: "block"}}>Curso:</Text>
                                    <Text strong>{aluno.curso.nome}</Text>
                                  </div>
                                  <div>
                                    <Text style={{display: "block"}}>* Ano de ingresso:</Text>
                                    <Input
                                      value={curso_ano}
                                      onChange={onCursoAnoChange}
                                      placeholder="Ex. 2019"/>
                                  </div>
                                </Space>
                              )}

                              <Text>* Recebe alguma renda no momento?</Text>
                              <Radio.Group
                                onChange={onTrabalhaRadioChange}
                                value={trabalha}>
                                <Radio value="N">Não</Radio>
                                <Radio value="S">Sim</Radio>
                              </Radio.Group>

                              {(trabalha === "S") && (
                                <Space
                                  direction="horizontal">
                                    <div>
                                      <Text style={{display: "block"}}>* Descrição da(s) renda(s):</Text>
                                      <Input
                                        value={trabalho_descricao}
                                        maxLength={30}
                                        onChange={(e)=>this.setState({trabalho_descricao: e.target.value})} />
                                    </div>
                                    <div>
                                      <Text style={{display: "block"}}>* Renda mensal:</Text>
                                      <Input
                                        value={trabalho_remuneracao}
                                        onChange={onTrabalhoRendaChange} />
                                    </div>
                                </Space>
                              )}

                              <Text type="danger">Observação: Contabilize como renda valores proveniente de trabalho/atividade que desenvolve, aposentadoria e/ou pensão do INSS, pensão alimentícia e renda de aluguel de
                              imóvel. BPC, Bolsa Família, ajuda de terceiros e seguro desemprego não são contabilizados como renda. A soma dessas rendas deverá ser informada corretamente no questionário socioeconômico e incluída as comprovações nos anexos.</Text>

                              <Text underline>Dados bancários</Text>

                              <Text>* Tipo da conta</Text>
                              <Radio.Group
                                onChange={onContaTipo}
                                value={conta_tipo}>
                                <Radio value={-1}>Não possuo no momento</Radio>
                                <Radio value={0}>Poupança</Radio>
                                <Radio value={1}>Corrente</Radio>
                              </Radio.Group>

                              <Space
                                direction="horizontal">
                                  <div>
                                      <Text style={{display: "block"}}>Banco:</Text>
                                      <Input
                                        value={conta_banco}
                                        maxLength={40}
                                        onChange={(e)=>this.setState({conta_banco: e.target.value})}
                                        placeholder="Ex. Caixa"/>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>Agência:</Text>
                                      <Input
                                        value={conta_agencia}
                                        maxLength={10}
                                        onChange={(e)=>this.setState({conta_agencia: e.target.value})}
                                        placeholder="Ex. 100-1"/>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>Número da conta:</Text>
                                      <Input
                                        value={conta_numero}
                                        maxLength={20}
                                        onChange={(e)=>this.setState({conta_numero: e.target.value})}
                                        placeholder="Ex. 213234-5"/>
                                  </div>
                              </Space>

                              <Text underline>Contato</Text>

                              <Space
                                  direction="horizontal">
                                  <div>
                                    <Text style={{display: "block"}}>E-mail:</Text>
                                    <Text strong>{aluno.email}</Text>
                                  </div>
                                  <div>
                                    <Text style={{display: "block"}}>* Celular:</Text>
                                    <InputMask
                                      className="ant-input"
                                      mask="\(99\) \99999\-9999"
                                      value={contato_celular}
                                      onChange={e=>this.setState({contato_celular: e.target.value})}
                                      />
                                  </div>
                                </Space>

                              <Text>Após iniciar a inscrição, será necessário completar outras etapas para finalizar a inscrição. Você terá até as <Text strong type="danger">23:59 do dia {edital_selecionado.inscricoes_ate}</Text> para finalizar a inscrição.</Text>

                              {(messageError.length > 0) &&
                              <div className="alert-box">
                                <Text style={{display: "block"}}>Problemas encontrados:</Text>
                                {messageError.map((message, i)=>
                                    <Text key={i} style={{display: "block"}}>🔸 {message}</Text>
                                )}
                              </div>
                              }
                              <Space
                                direction="horizontal">
                                <Button
                                  onClick={()=>this.props.history.push('/')}>Cancelar</Button>
                                {processando ? (
                                  <Button
                                    type="primary" loading>Aguarde ...</Button>
                                ):(
                                  <Button
                                    onClick={iniciaInscricaoClick}
                                    type="primary">Iniciar inscrição</Button>
                                )}

                              </Space>

                            </Space>

                          </div>

                        <div
                          style={{width: "100%", minHeight: "30px"}}>

                        </div>
                      </Space>

                  </Content>
            </Layout>

        </LayoutSite>
    );
  }
}

export default withRouter(
  connect(
    state=>({
      aluno: state.aluno,
      edital_selecionado: state.edital_selecionado})
  )(windowSize(NovaInscricao)));