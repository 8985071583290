import React from 'react';

import { 
  Layout, 
  Space, 
  Breadcrumb, 
  Typography,
  Radio,
  Input,
  Button,
  Select} from 'antd';

import axios from 'axios';

import InputMask from 'react-input-mask';

import windowSize from 'react-window-size';
import LayoutSite from '../../componentes/LayoutSite';


const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;


class EdicaoComponenteFamiliar extends React.Component {  

  constructor(props){
    super(props);

    const { familiarSelecionado } = props;

    this.state = {
      processando: false,  
      messageError: [],
      nome: (familiarSelecionado !== undefined) ? familiarSelecionado.nome : "",
      sexo: (familiarSelecionado !== undefined) ? familiarSelecionado.sexo : "",
      data_nascimento: (familiarSelecionado !== undefined) ? familiarSelecionado.data_nascimento : "",
      parentesco: (familiarSelecionado !== undefined) ? familiarSelecionado.parentesco : "",
      estuda: (familiarSelecionado !== undefined) ? familiarSelecionado.estuda : "",
      alfabetizado: (familiarSelecionado !== undefined) ? familiarSelecionado.alfabetizado : "",
      estudo_ano: (familiarSelecionado !== undefined) ? familiarSelecionado.estudo_ano : 1,
      trabalha: (familiarSelecionado !== undefined) ? familiarSelecionado.trabalha : "",
      trabalho_descricao: (familiarSelecionado !== undefined) ? familiarSelecionado.trabalho_descricao : "",
      trabalho_remuneracao: (familiarSelecionado !== undefined) ? familiarSelecionado.trabalho_remuneracao : "",
      familiarRef: (familiarSelecionado !== undefined) ? familiarSelecionado.key : undefined,
    }

    this.onTrabalhaRadioChange = this.onTrabalhaRadioChange.bind(this);
    this.onTrabalhoRendaChange = this.onTrabalhoRendaChange.bind(this);
    this.parentescoDescricao = this.parentescoDescricao.bind(this);
    this.salvarEdicao = this.salvarEdicao.bind(this);

  }

  onTrabalhaRadioChange(e){
    const { value } = e.target;

    if (value === 'S')
      this.setState({
        trabalha: value,
      });
    else 
      this.setState({
        trabalha: value,
        trabalho_descricao: "",
        trabalho_remuneracao: "",
      });
  }

  onTrabalhoRendaChange(e){
    const { value } = e.target;
    const reg = /^\d{1,5}(\,\d{0,2})?$/i;
    if (reg.test(value) || value === '') {
      this.setState({
        trabalho_remuneracao: value,
      })
    }
  }

  parentescoDescricao(indice){

    let descricao = '';

    switch (indice){
        case 0:
            descricao = 'Pai';
            break;
        case 1:
            descricao = 'Mãe';
            break;
        case 2:
            descricao = 'Filho(a)';
            break;
        case 3:
            descricao = 'Tio(a)';
            break;
        case 4:
            descricao = 'Primo(a)';
            break;
        case 5:
            descricao = 'Avô(ó)';
            break;
        case 6:
            descricao = 'Neto(a)';
            break;
        case 7:
            descricao = 'Outros';
            break;
        default:
            descricao = 'Sem Parentesco';
    }
    return descricao;
  }

  salvarEdicao(){
    const { familiarRef, nome, parentesco, sexo, 
        data_nascimento, estuda, alfabetizado, estudo_ano,
        trabalha, trabalho_descricao, trabalho_remuneracao } = this.state; 
       
    const { auth_token } = this.props;
    
    let messageError = [];
 
    if (!nome.length)
      messageError.push('O nome não foi preenchido.');  
    if (!parentesco.length)  
      messageError.push('O parentesco não foi informado.');    
    if (!sexo.length)  
      messageError.push('O sexo não foi informado.');
    if (!data_nascimento.length)  
      messageError.push('A data de nascimento não foi preenchida.');
    if (!estuda.length)  
      messageError.push('Informe se essa pessoa estuda.');    
    if (!alfabetizado.length)  
      messageError.push('Informe se essa pessoa é alfabetizada.');
    if (estudo_ano === 1)  
      messageError.push('A última série que esssa pessoa estuda ou estudou, não foi preenchida.');
    
    if (!trabalha.length)
      messageError.push('Informe se essa pessoa trabalha.');
    else if (trabalha === 'S'){
      if (!trabalho_descricao.length)
        messageError.push('A descrição do trabalho não foi preenchida.');
      if (!trabalho_remuneracao.length)
        messageError.push('A remuneração do trabalho não foi preenchida.');
    }
    
    if (messageError.length)
      this.setState({messageError});
    else
      this.setState({messageError, processando: true}, ()=>{
        //envia requisição para o webservice
        let qs = require('qs');

        const config = {
          "Content-Type": "application/x-www-form-urlencoded"
        }

        const requestBody = qs.stringify(
          { auth_token, familiarRef, nome, parentesco, sexo, 
            data_nascimento, estuda, alfabetizado, estudo_ano,
            trabalha, trabalho_descricao, trabalho_remuneracao }
        );

        axios.post(process.env.REACT_APP_API_URL        
          + 'edicaoComponenteFamilia',
          requestBody,
          config)
          .then(res => {
          if (res.data.sucesso){

            this.props.onEdicaoSucesso();

          } else {

            res.data.message.map(message=>messageError.push(message));
            
          }         
          })
          .catch((error) => {
            messageError.push("Verifique sua Internet e tente novamente.")
            this.setState({
              processando: false,
              messageError,
            });
          })
          .finally(()=>{
            if (this._mounted)
              this.setState({
                  processando: false,
                  messageError
                });
          });
      });

  }

  componentWillUnmount(){
    this._mounted = false;
  }
  
  componentDidMount(){      
    this._mounted = true;
    window.scrollTo(0, 0);   
  } 

  render() {
    const {processando, messageError, familiarRef, nome, parentesco, sexo, 
        data_nascimento, estuda, alfabetizado, estudo_ano,
        trabalha, trabalho_descricao, trabalho_remuneracao } = this.state;     
    
    const windowWidth = this.props.windowWidth;
    
    const titulo_edicao = (familiarRef === undefined) ? "Adicionar" : "Editar";
    const titulo_botao = (familiarRef === undefined) ? "Salvar" : "Alterar";
    const { onTrabalhaRadioChange, onTrabalhoRendaChange, salvarEdicao } = this;  

    return (

        <LayoutSite>
          
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Assistência Estudantil</Breadcrumb.Item>
              <Breadcrumb.Item>Inscrição atual</Breadcrumb.Item>
              <Breadcrumb.Item>2. Componentes da família</Breadcrumb.Item>

            </Breadcrumb>          

            <Layout className="site-layout-background" 
              style={{ 
                marginTop: "24px",
                padding: '12px 0' }}>            
                  <Content 
                    style={{ padding: '0 12px', minHeight: 280 }}>
                      <Space 
                        direction="vertical"
                        style={{width: "100%"}}>
                          <Button 
                            type="link" 
                            onClick={()=>this.props.onCancelaClick()}
                            style={{padding: "0"}}>Voltar</Button>
                          <Title level={4}>{titulo_edicao} componente</Title>
                                    
                          <div>
                            <Space 
                              direction="vertical"
                              style={{width: "100%"}}>
                              <Text>Preencha os campos abaixo com as informações sobre o componente da sua família.</Text>  
                            
                              <Text style={{display: "block"}}>* Nome:</Text>
                              <Input 
                                value={nome}
                                maxLength={45}
                                onChange={(e)=>this.setState({nome: e.target.value})}
                                style={{ maxWidth: 540 }}/>                                

                              <Text>* Sexo</Text>
                              <Radio.Group 
                                onChange={(e)=>this.setState({sexo: e.target.value})} 
                                value={sexo}>
                                <Radio value="M">Masculino</Radio>
                                <Radio value="F">Feminino</Radio>
                              </Radio.Group>
                              
                              <Text style={{display: "block"}}>* Data de nascimento:</Text>
                              <InputMask 
                                      className="ant-input"
                                      maskchar="_"
                                      mask="99\/99\/9999" 
                                      value={data_nascimento} 
                                      onChange={e=>this.setState({data_nascimento: e.target.value})}
                                      style={{ maxWidth: 150 }}/>
                              
                              <Text style={{display: "block"}}>* Parentesco:</Text>
                              <Select 
                                value={parentesco}
                                style={{ width: 200 }} 
                                onChange={(valor)=>{this.setState({parentesco: valor})}}>

                                <Option value="0">Pai</Option>
                                <Option value="1">Mãe</Option>
                                <Option value="2">Irmão(ã)</Option>
                                <Option value="3">Filho(a)</Option>
                                <Option value="4">Tio(a)</Option>
                                <Option value="5">Primo(a)</Option>
                                <Option value="6">Avô(ó)</Option>
                                <Option value="7">Neto(a)</Option>
                                <Option value="8">Outros</Option>
                                <Option value="9">Sem parentesco</Option>
                              </Select>

                              <Text>* Essa pessoa estuda atualmente?</Text>
                              <Radio.Group 
                                onChange={(e)=>this.setState({estuda: e.target.value})} 
                                value={estuda}>
                                <Radio value="S">Sim</Radio>
                                <Radio value="N">Não</Radio>
                              </Radio.Group>
                            
                              <Text>* Essa pessoa é alfabetizada?</Text>
                              <Radio.Group 
                                onChange={(e)=>this.setState({alfabetizado: e.target.value})} 
                                value={alfabetizado}>
                                <Radio value="S">Sim</Radio>
                                <Radio value="N">Não</Radio>
                              </Radio.Group>
                              
                              <Text style={{display: "block"}}>* Qual a última série ou ano que essa pessoa estuda ou estudou?</Text>
                              
                              <Select 
                                value={estudo_ano}
                                style={{ width: 300 }} 
                                onChange={(valor)=>{this.setState({estudo_ano: valor})}}>
                                <Option value={1}>Nenhuma</Option>
                                <Option value={2}>Educação infantil ou Pré-escola</Option>
                                <Option value={3}>1º ano do Ensino Fundamental</Option>
                                <Option value={4}>2º ano do Ensino Fundamental</Option>
                                <Option value={5}>3º ano do Ensino Fundamental</Option>
                                <Option value={6}>4º ano do Ensino Fundamental</Option>
                                <Option value={7}>5º ano do Ensino Fundamental</Option>
                                <Option value={8}>6º ano do Ensino Fundamental</Option>
                                <Option value={9}>7º ano do Ensino Fundamental</Option>
                                <Option value={10}>8º ano do Ensino Fundamental</Option>
                                <Option value={11}>9º ano do Ensino Fundamental</Option>
                                <Option value={12}>1º ano do Ensino Médio</Option>
                                <Option value={13}>2º ano do Ensino Médio</Option>
                                <Option value={14}>3º ano do Ensino Médio</Option>
                              </Select>

                              <Text>* Essa pessoa recebe alguma renda?</Text>
                              <Radio.Group 
                                onChange={onTrabalhaRadioChange} 
                                value={trabalha}>
                                <Radio value="S">Sim</Radio>
                                <Radio value="N">Não</Radio>
                              </Radio.Group> 

                              <Text type="danger">Observação: Contabilize como renda valores proveniente de trabalho/atividade que desenvolve, aposentadoria e/ou pensão do INSS, pensão alimentícia e renda de aluguel de
imóvel. BPC, Bolsa Família, ajuda de terceiros e seguro desemprego não são contabilizados como renda. A soma dessas rendas deverá ser informada corretamente no questionário socioeconômico e incluída as comprovações nos anexos.</Text>

                              {(trabalha === "S") && (
                                <Space
                                  direction="vertical">
                                    <div>
                                      <Text style={{display: "block"}}>* Descrição da(s) renda(s):</Text>
                                      <Input 
                                        value={trabalho_descricao}
                                        maxLength={30}
                                        onChange={(e)=>this.setState({trabalho_descricao: e.target.value})} />
                                    </div>
                                    <div>
                                      <Text style={{display: "block"}}>* Renda mensal:</Text>
                                      <Input 
                                        value={trabalho_remuneracao}
                                        onChange={onTrabalhoRendaChange} />                                      
                                    </div>                                    
                                </Space>
                              )}
                              
                              {(messageError.length > 0) &&
                              <div className="alert-box">
                                <Text style={{display: "block"}}>Problemas encontrados:</Text>
                                {messageError.map((message, i)=> 
                                    <Text key={i} style={{display: "block"}}>🔸 {message}</Text>
                                )}
                              </div>
                              }   

                              <Space
                                direction="horizontal"
                                style={{marginTop: "15px"}}>
                                <Button
                                  onClick={()=>{this.props.onCancelaClick()}}>Cancelar</Button>
                                {processando ? (
                                  <Button 
                                    type="primary" loading>Aguarde ...</Button>
                                ):(
                                  <Button 
                                    onClick={salvarEdicao}
                                    type="primary">{titulo_botao}</Button>
                                )}                             
                              </Space>
                            </Space>
                            
                          </div>
                                                   
                        <div
                          style={{width: "100%", minHeight: "30px"}}>

                        </div>
                      </Space>
                      
                  </Content>
            </Layout>
          
        </LayoutSite>        
    );
  }
}

export default windowSize(EdicaoComponenteFamiliar);