import React from 'react';

import { 
  Layout, 
  Space, 
  Breadcrumb, 
  Typography,
  Button,
  Checkbox,
  Popconfirm,
  Alert,
  message} from 'antd';

import axios from 'axios';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import windowSize from 'react-window-size';
import LayoutSite from '../../componentes/LayoutSite';
import DadosPessoais from './DadosPessoais';
import ComponentesFamilia from './ComponentesFamilia';
import Anexos from './Anexos';
import Questionario from './Questionario';


const { Content } = Layout;
const { Title, Text } = Typography;


class InscricaoAtual extends React.Component {  

  constructor(props){
    super(props);

    this.state = {
      processando: false,    
      messageError: '',  
      etapa: 0, 
      ciencia_inscricao: false, 
      autorizado: (props.aluno.auth_token.length 
        && props.aluno.inscricao_numero_ref !== undefined),
    }

    this.voltarClick = this.voltarClick.bind(this);
    this.atualizaDadosPessoais = this.atualizaDadosPessoais.bind(this);
    this.confirmaCancelaInscricao = this.confirmaCancelaInscricao.bind(this);
    this.finalizaInscricao = this.finalizaInscricao.bind(this);
    this.etapaAnexos = this.etapaAnexos.bind(this);

    if (!this.state.autorizado)
      this.props.history.push('/');

  }

  componentWillUnmount(){
    this._mounted = false;
  }
  
  componentDidMount(){
    this._mounted = true;
  }

  voltarClick(){
    this.setState({
      etapa: 0,
    }, ()=>{
      window.scrollTo(0, 0);
    });
  }

  etapaAnexos(){
    this.setState({etapa: 4, messageError: ''});
  }

  atualizaDadosPessoais(aluno){
    
    this.setState({etapa: 0}, ()=>{
      //dispatch com atualização dos dados pessoais           
      this.props.dispatch({
        type: 'ATUALIZA_DADOS',
        aluno
      });

      message.success('Dados pessoais atualizados com sucesso!'); 
    });
  }

  confirmaCancelaInscricao(){
    this.setState({processando_cancela: true}, ()=>{
      //envia requisição para o webservice
      let qs = require('qs');

      const config = {
        "Content-Type": "application/x-www-form-urlencoded"
      }

      const { auth_token, inscricao_numero_ref } = this.props.aluno;

      const requestBody = qs.stringify(
        { auth_token, inscricao_numero_ref }
      );

      axios.post(process.env.REACT_APP_API_URL        
        + 'cancelaInscricao',
        requestBody,
        config)
        .then(res => {          
          this.props.dispatch({
            type: 'LIMPA_INSCRICAO'
          });
          message.success('Inscrição cancelada com sucesso!');
          this.props.history.push("/");                  
        })
        .catch((error) => {
          message.error("Falha na comunicação. Verifique sua conexão com a Internet e tente novamente.");          
        })
        .finally(()=>{
          if (this._mounted)
            this.setState({processando_cancela: false});
        });
    });
  }

  finalizaInscricao(){
    this.setState({processando: true}, ()=>{
      //envia requisição para o webservice
      let qs = require('qs');

      const config = {
        "Content-Type": "application/x-www-form-urlencoded"
      }

      const { auth_token, inscricao_numero_ref } = this.props.aluno;

      const requestBody = qs.stringify(
        { auth_token, inscricao_numero_ref }
      );

      axios.post(process.env.REACT_APP_API_URL        
        + 'finalizaInscricao',
        requestBody,
        config)
        .then(res => {  
          if (res.data.sucesso){
            this.props.dispatch({
              type: 'FINALIZA_INSCRICAO',
            });          
            this.props.history.push("/"); 
          } else 
            this.setState({messageError: res.data.msg});             
                           
        })
        .catch((error) => {
          message.error("Falha na comunicação. Verifique sua conexão com a Internet e tente novamente.");          
        })
        .finally(()=>{
          if (this._mounted)
            this.setState({processando: false});
        });
    });
  }

  render() {
    const { processando, processando_cancela, autorizado, messageError, 
      etapa } = this.state;
    const { aluno } = this.props;
    const { voltarClick, confirmaCancelaInscricao, atualizaDadosPessoais,
      finalizaInscricao, etapaAnexos } = this;
    const windowWidth = this.props.windowWidth;

    let etapaRender = null;

    if (!autorizado)
      return etapaRender;

    switch (etapa){      
        case 1:
          etapaRender = <DadosPessoais 
            aluno={aluno} 
            onAtualizaDados={atualizaDadosPessoais}
            onVoltarClick={voltarClick} />;
          break;
        case 2:
            etapaRender = <ComponentesFamilia
              aluno={aluno} 
              onVoltarClick={voltarClick} />;
            break;
        case 3:
            etapaRender = <Questionario
              aluno={aluno} 
              onProximaEtapa={etapaAnexos}
              onVoltarClick={voltarClick}/>
            break;
        case 4:
            etapaRender = <Anexos
              aluno={aluno}
              onVoltarClick={voltarClick} />;
            break;
        default:
          etapaRender =
            <LayoutSite>
              
                <Breadcrumb style={{ margin: '16px 0' }}>
                  <Breadcrumb.Item>Assistência Estudantil</Breadcrumb.Item>
                  <Breadcrumb.Item>Inscrição atual</Breadcrumb.Item>
                </Breadcrumb>          
  
                <Layout className="site-layout-background" 
                  style={{ 
                    marginTop: "24px",
                    padding: '12px 0' }}>            
                      <Content 
                        style={{ padding: '0 12px', minHeight: 280 }}>
                          <Space 
                            direction="vertical"
                            style={{width: "100%"}}>
                              <Button 
                                type="link" 
                                onClick={()=>this.props.history.push("/")}
                                style={{padding: "0"}}>Início</Button> 
                              <Title level={3}>Inscrição - Edital {aluno.inscricao_edital_numero}</Title>                                       
                              <div>
                                <Space 
                                  direction="vertical"
                                  style={{width: "100%"}}>
                                  <Alert message="Inscrição incompleta! Somente inscrições finalizadas serão analisadas no processo seletivo. Leia atentamente as instruções a seguir." type="warning" showIcon />
                                  <Text><Text strong>Complete as etapas abaixo</Text> para finalizar a inscrição. Caso tenha errado na escolha do Edital ou auxílios, você poderá cancelar a inscrição e realizar uma nova.</Text>
                                  <Text>Você terá até as <Text strong type="danger">23:59 do dia {aluno.inscricao_edital_data_limite}</Text> para finalizar a inscrição.</Text>
                                  
                                  <Text strong>Número da inscrição: </Text>
                                  <Text>{aluno.inscricao_numero}</Text>
                                  <Text strong>Auxílio(s) desta inscrição:</Text>
                                  {aluno.inscricao_auxilios.map(auxilio=>
                                      <Text key={auxilio.ref}>🔸 {auxilio.descricao}</Text>
                                    )}    
                                                          
                                  
                                  <Text strong>Etapas:</Text>
                                  <Button
                                    onClick={()=>this.setState({etapa: 1, messageError: ''})}>1. Dados pessoais</Button>
                                  <Button
                                    onClick={()=>this.setState({etapa: 2, messageError: ''})}>2. Componentes da família</Button>
                                  <Button
                                    onClick={()=>this.setState({etapa: 3, messageError: ''})}>3. Questionário</Button>
                                  <Button
                                    onClick={()=>this.setState({etapa: 4, messageError: ''})}>4. Anexos da documentação</Button>
                                  <Text strong>Declaração:</Text>
                                  <Text>Declaro para os devidos fins que li e estou de acordo com o Edital e que <Text strong type="danger">estou ciente que não poderei alterar os dados da inscrição após finalizar</Text>. Responsabilizando-me pela veracidade das informações apresentadas. Estou ciente de que a constatação de fraude ou omissão nas informações declaradas acarreta o cancelamento do benefício, bem como a devolução da(s) parcela(s) recebida(s) indevidamente em valores atualizados ao IF Sertão Pernambucano.</Text>
                                  <Checkbox 
                                        checked={this.state.ciencia_inscricao}
                                        onChange={(e)=>this.setState({ciencia_inscricao: e.target.checked})}>
                                          Concordo e declaro estar ciente com o exposto acima.
                                  </Checkbox>
  
                                  {(messageError.length > 0) &&
                                    <Alert
                                      message="Problema encontrado"
                                      description={messageError}
                                      type="warning"
                                      showIcon
                                    />
                                  }

                                  <Space
                                    direction="horizontal"
                                    style={{marginTop: "15px"}}>    
                                    <Popconfirm 
                                      placement="topLeft" 
                                      title="Confirma o cancelamento da inscrição?" 
                                      onConfirm={confirmaCancelaInscricao} 
                                      okText="Sim" cancelText="Não">
                                      {processando_cancela ? (
                                        <Button type="danger" loading>Aguarde ...</Button>

                                      ):(
                                        <Button type="danger">Cancelar inscrição</Button>
                                      )}

                                    </Popconfirm>
                                                            
                                    {(processando && !processando_cancela) ? (
                                      <Button 
                                        type="primary" loading>Aguarde ...</Button>
                                    ):(
                                      <Button   
                                        onClick={finalizaInscricao}
                                        disabled={!this.state.ciencia_inscricao}                                  
                                        type="primary">Finalizar inscrição</Button>
                                    )}
                                    
                                  </Space>
                                  
                                </Space>
                                
                              </div>
                                                      
                            <div
                              style={{width: "100%", minHeight: "30px"}}>
  
                            </div>
                          </Space>
                          
                      </Content>
                </Layout>
              
            </LayoutSite>;
    } 
      
    
    return etapaRender;
    
  }
}

export default withRouter(
  connect(
    state=>({
      aluno: state.aluno})
  )(windowSize(InscricaoAtual)));