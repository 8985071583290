import React from 'react';

import {
  Layout,
  Space,
  Breadcrumb,
  Typography,
  Button,
  Skeleton,
  message,
  Tag,
  Alert,
  Collapse} from 'antd';

import axios from 'axios';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import windowSize from 'react-window-size';
import LayoutSite from '../../componentes/LayoutSite';


const { Content } = Layout;
const { Title, Text } = Typography;
const { Panel } = Collapse;

class InscricaoDetalhe extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      processando: false,/*
      messageError: '',
      etapa: 0,
      ciencia_inscricao: false, */
      inscricao: {},
      autorizado: (props.aluno.auth_token.length),
    }
    // this.atualizaDadosPessoais = this.atualizaDadosPessoais.bind(this);
    // this.confirmaCancelaInscricao = this.confirmaCancelaInscricao.bind(this);
    // this.finalizaInscricao = this.finalizaInscricao.bind(this);
    this.carregaInscricao = this.carregaInscricao.bind(this);

    if (!this.state.autorizado)
      this.props.history.push('/');

  }

  componentWillUnmount(){
    this._mounted = false;
  }

  componentDidMount(){
    this._mounted = true;
    window.scrollTo(0, 0);
    this.carregaInscricao();
  }

  carregaInscricao(){
    const { auth_token } = this.props.aluno;
    const inscricao_ref = this.props.inscricao;
    var qs = require('qs');

    const config = {
        "Content-Type": "application/x-www-form-urlencoded"
    }
    const requestBody = qs.stringify({
        auth_token,
        inscricao_ref
    });

    this.setState({processando: true, familiares: []}, ()=>{
      axios.post(process.env.REACT_APP_API_URL
        + 'inscricaoDetalhe',
        requestBody,
        config)
        .then(res => {
            if (res.data.sucesso){
                let inscricao = res.data.inscricao;

                this.setState({
                    inscricao,
                });
            }
        })
        .catch((error) => {
          message.error('Falha na conexão, verifique sua conexão com a Internet e tente recarregar a página.');
        })
        .finally(() => {
          this.setState({ processando: false });
        });
    });
  }

  render() {
    const { autorizado, etapa, processando, inscricao } = this.state;
    const windowWidth = this.props.windowWidth;

    return (
        <LayoutSite>

            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item href="./">Assistência Estudantil</Breadcrumb.Item>
                <Breadcrumb.Item href="./inscricoes">Minhas inscrições</Breadcrumb.Item>
                <Breadcrumb.Item>{this.props.inscricao_numero}</Breadcrumb.Item>
            </Breadcrumb>

            <Layout className="site-layout-background"
                style={{
                marginTop: "24px",
                padding: '12px 0' }}>
                    <Content
                    style={{ padding: '0 12px', minHeight: 280 }}>
                        <Space
                        direction="vertical"
                        style={{width: "100%"}}>
                            <Button
                                type="link"
                                onClick={()=>this.props.onVoltarClick()}
                                style={{padding: "0"}}>
                                    Voltar
                            </Button>
                            <Title level={4} style={{ marginBottom: 0}}>Inscrição {this.props.inscricao_numero}</Title>
                            <Text strong style={{fontSize: "16px", color: "#0000005c"}}>Edital {inscricao.edital}</Text>
                            {inscricao.expirada &&
                                <Alert message={inscricao.expirada} type="warning" showIcon/>
                            }
                            {processando ? (
                                <div>
                                    <Text>Carregando ...</Text>
                                    <Skeleton active />
                                </div>
                            ) : (
                                <Space
                                    direction="vertical"
                                    style={{width: "100%"}}>
                                    {(windowWidth < 640) ? (
                                        <>
                                            <Space
                                                direction="vertical"
                                                width="100%">
                                                {inscricao.pontuacao_barema &&
                                                    <>
                                                        <Text>Pontuação Barema:</Text>
                                                        <Text strong>{inscricao.pontuacao_barema}</Text>
                                                    </>
                                                }
                                                {inscricao.pontuacao_entrevista &&
                                                    <>
                                                        <Text>Pontuação Entrevista:</Text>
                                                        <Text strong>{inscricao.pontuacao_entrevista}</Text>
                                                    </>
                                                }
                                                {inscricao.pontuacao_final &&
                                                    <>
                                                        <Text>Pontuação Final:</Text>
                                                        <Text strong>{inscricao.pontuacao_final}</Text>
                                                    </>
                                                }
                                            </Space>
                                            <table style={{width: "100%"}}>
                                                <thead className="ant-table-thead">
                                                    <tr>
                                                        <th className="ant-table-cell">Auxílio concorrido</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="ant-table-tbody">
                                                    {inscricao.auxilios &&
                                                     inscricao.auxilios.map(rowData =>
                                                    <tr
                                                        key={rowData.key}
                                                        className="ant-table-row ant-table-row-level-0">
                                                        <td className="ant-table-cell">
                                                            <Space
                                                                direction="vertical"
                                                                width="100%">
                                                                <Text strong>{rowData.nome}</Text>
                                                                <Space
                                                                    direction="horizontal"
                                                                    width="100%">
                                                                    <Text strong>Vagas:</Text>
                                                                    <Text>{rowData.vagas}</Text>
                                                                    <Text strong>Valor:</Text>
                                                                    <Text>{rowData.valor}</Text>
                                                                </Space>
                                                                <Space
                                                                    direction="horizontal"
                                                                    width="100%">
                                                                    <Text strong>Situação:</Text>
                                                                    <Text>{rowData.situacao}</Text>
                                                                </Space>
                                                                {rowData.motivo &&
                                                                    <>
                                                                        <Text strong>Motivo:</Text>
                                                                        <Text>{rowData.motivo}</Text>
                                                                    </>
                                                                }
                                                                {rowData.classificacao &&
                                                                    <>
                                                                        <Text>Classificação: {rowData.classificacao}</Text>
                                                                        {(rowData.classificacao <= rowData.vagas) ? (
                                                                            <Tag
                                                                                color="green"
                                                                                style={{fontSize: "14px"}}>Aprovado
                                                                            </Tag>
                                                                        ):(
                                                                            <Tag
                                                                                color="geekblue"
                                                                                style={{fontSize: "14px"}}>Lista de Espera
                                                                            </Tag>
                                                                        )}
                                                                    </>
                                                                }
                                                            </Space>
                                                        </td>
                                                    </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            <Collapse>
                                                <Panel header="Questionário" key="1">
                                                    {inscricao.questoes && inscricao.questoes.map(rowData =>
                                                        <>
                                                            <Text style={{
                                                                color: 'blue',
                                                                textAlign: 'justify',
                                                                display: 'block'}}>
                                                                {rowData.enunciado}
                                                            </Text>
                                                            {(rowData.tipo === 'Discursiva' && rowData.discursiva) &&
                                                                <Text style={{
                                                                    color: 'black',
                                                                    textAlign: 'justify',
                                                                    display: 'block'}}>
                                                                    {rowData.discursiva}
                                                                </Text>
                                                            }
                                                            <ul>
                                                                {(rowData.tipo === 'Objetiva' && rowData.objetivas) &&
                                                                    rowData.objetivas.map(alternativa =>
                                                                        <li style={{
                                                                            color: 'black',
                                                                            textAlign: 'justify' }}>
                                                                            {alternativa.descritivo}
                                                                            {alternativa.discursiva &&
                                                                                <p style={{
                                                                                    marginLeft: '2px',
                                                                                    color: 'black',
                                                                                    display: 'inline' }}>
                                                                                    ({alternativa.discursiva})
                                                                                </p>
                                                                            }
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                        </>
                                                    )}
                                                </Panel>
                                                <Panel header="Anexos da documentação" key="2">
                                                    <table style={{width: "100%"}}>
                                                        <thead className="ant-table-thead">
                                                            <tr>
                                                                <th className="ant-table-cell">Tipo</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="ant-table-tbody">
                                                            {inscricao.anexos &&
                                                            inscricao.anexos.map(rowData =>
                                                            <tr
                                                                key={rowData.key}
                                                                className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">
                                                                    <Space
                                                                        direction="vertical"
                                                                        width="100%">
                                                                        <Text strong>{rowData.tipo}</Text>
                                                                        <Space
                                                                            direction="horizontal"
                                                                            width="100%">
                                                                            <Text strong>Arquivo:</Text>
                                                                            <Text>{rowData.nome_arquivo}</Text>
                                                                        </Space>
                                                                        <Space
                                                                            direction="horizontal"
                                                                            width="100%">
                                                                            <Text strong>Enviado Em:</Text>
                                                                            <Text>{rowData.enviado_em}</Text>
                                                                        </Space>
                                                                        {rowData.anexado_equipe &&
                                                                            <Tag
                                                                                color="blue"
                                                                                style={{fontSize: "14px"}}>Anexado pela comissão
                                                                            </Tag>
                                                                        }
                                                                        <a href={rowData.url}
                                                                            target='_blank'>
                                                                                Visualizar
                                                                        </a>
                                                                    </Space>
                                                                </td>
                                                            </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </Panel>
                                                {inscricao.recurso &&
                                                    <Panel header="Recurso" key="3">
                                                        <Text strong style={{display: 'block'}}>Situação:</Text>
                                                        <Text style={{display: 'block'}}>{inscricao.recurso.situacao}</Text>
                                                        <Text strong style={{display: 'block'}}>Argumentação:</Text>
                                                        <Text style={{ display: 'block', textAlign: 'justify'}}>
                                                            {inscricao.recurso.argumentacao}
                                                        </Text>
                                                        {inscricao.recurso.parecer &&
                                                            <>
                                                                <Text strong style={{display: 'block'}}>Parecer:</Text>
                                                                <Text style={{ display: 'block', textAlign: 'justify'}}>
                                                                    {inscricao.recurso.parecer}
                                                                </Text>
                                                            </>
                                                        }
                                                        {inscricao.recurso_anexos &&
                                                            <table style={{width: "90%"}}>
                                                                <thead className="ant-table-thead">
                                                                    <tr>
                                                                        <th className="ant-table-cell">Anexo</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ant-table-tbody">
                                                                    {inscricao.recurso_anexos &&
                                                                    inscricao.recurso_anexos.map(rowData =>
                                                                    <tr
                                                                        key={rowData.key}
                                                                        className="ant-table-row ant-table-row-level-0">
                                                                        <td className="ant-table-cell" style={{ width: '90%' }}>
                                                                            <Text strong style={{
                                                                                display: 'block',
                                                                                textAlign: 'justify',
                                                                                maxWidth: '262px'}}>{rowData.nome_arquivo}</Text>
                                                                            <Space
                                                                                direction="horizontal"
                                                                                width="100%">
                                                                                <Text strong>Enviado em:</Text>
                                                                                <Text>{rowData.enviado_em}</Text>
                                                                            </Space>
                                                                            <a href={rowData.url}
                                                                                target='_blank'
                                                                                style={{display: 'block' }}>
                                                                                    Visualizar
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        }
                                                    </Panel>
                                                }
                                            </Collapse>
                                        </>
                                    ):(
                                        <>
                                            <Space
                                                direction="horizontal"
                                                width="100%">
                                                {inscricao.pontuacao_barema &&
                                                    <>
                                                        <Text>Pontuação Barema:</Text>
                                                        <Text strong>{inscricao.pontuacao_barema}</Text>
                                                    </>
                                                }
                                                {inscricao.pontuacao_entrevista &&
                                                    <>
                                                        <Text>Pontuação Entrevista:</Text>
                                                        <Text strong>{inscricao.pontuacao_entrevista}</Text>
                                                    </>
                                                }
                                                {inscricao.pontuacao_final &&
                                                    <>
                                                        <Text>Pontuação Final:</Text>
                                                        <Text strong>{inscricao.pontuacao_final}</Text>
                                                    </>
                                                }
                                            </Space>
                                            <table style={{width: "100%"}}>
                                                <thead className="ant-table-thead">
                                                    <tr>
                                                        <th className="ant-table-cell">Auxílio concorrido</th>
                                                        <th className="ant-table-cell">Vagas</th>
                                                        <th className="ant-table-cell">Valor</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="ant-table-tbody">
                                                    {inscricao.auxilios
                                                    && inscricao.auxilios.map(rowData =>
                                                    <tr
                                                        key={rowData.key}
                                                        className="ant-table-row ant-table-row-level-0">
                                                        <td className="ant-table-cell">
                                                            <Text strong style={{display: "block"}}>{rowData.nome}</Text>
                                                            <Text style={{display: "block"}}>Situação: {rowData.situacao}</Text>
                                                            {rowData.motivo &&
                                                                <Text style={{display: "block"}}>Motivo: {rowData.motivo}</Text>
                                                            }
                                                            {rowData.classificacao &&
                                                                <>
                                                                    <Text style={{display: "block"}}>Classificação: {rowData.classificacao}</Text>
                                                                    {(rowData.classificacao <= rowData.vagas) ? (
                                                                        <Tag
                                                                            color="green"
                                                                            style={{fontSize: "14px"}}>Aprovado
                                                                        </Tag>
                                                                    ):(
                                                                        <Tag
                                                                            color="geekblue"
                                                                            style={{fontSize: "14px"}}>Lista de Espera
                                                                        </Tag>
                                                                    )}
                                                                </>
                                                            }
                                                        </td>
                                                        <td className="ant-table-cell">{rowData.vagas}</td>
                                                        <td className="ant-table-cell">{rowData.valor}</td>
                                                    </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            <Collapse>
                                                <Panel header="Questionário" key="1">
                                                {inscricao.questoes && inscricao.questoes.map(rowData =>
                                                        <>
                                                            <Text style={{
                                                                color: 'blue',
                                                                textAlign: 'justify',
                                                                display: 'block'}}>
                                                                {rowData.enunciado}
                                                            </Text>
                                                            {(rowData.tipo === 'Discursiva' && rowData.discursiva) &&
                                                                <Text style={{
                                                                    color: 'black',
                                                                    textAlign: 'justify',
                                                                    display: 'block'}}>
                                                                    {rowData.discursiva}
                                                                </Text>
                                                            }
                                                            <ul>
                                                                {(rowData.tipo === 'Objetiva' && rowData.objetivas) &&
                                                                    rowData.objetivas.map(alternativa =>
                                                                        <li style={{
                                                                            color: 'black',
                                                                            textAlign: 'justify' }}>
                                                                            {alternativa.descritivo}
                                                                            {alternativa.discursiva &&
                                                                                <p style={{
                                                                                    marginLeft: '2px',
                                                                                    color: 'black',
                                                                                    display: 'inline' }}>
                                                                                    ({alternativa.discursiva})
                                                                                </p>
                                                                            }
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                        </>
                                                    )}
                                                </Panel>
                                                <Panel header="Anexos da documentação" key="2">
                                                    <table style={{width: "100%"}}>
                                                        <thead className="ant-table-thead">
                                                            <tr>
                                                                <th className="ant-table-cell">Tipo</th>
                                                                <th className="ant-table-cell">Arquivo</th>
                                                                <th className="ant-table-cell">Enviado Em</th>
                                                                <th className="ant-table-cell">Ação</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="ant-table-tbody">
                                                            {inscricao.anexos && inscricao.anexos.map(rowData =>
                                                                <tr
                                                                    key={rowData.key}
                                                                    className="ant-table-row ant-table-row-level-0">
                                                                    <td className="ant-table-cell">
                                                                        <Text>{rowData.tipo}</Text>
                                                                    </td>
                                                                    <td className="ant-table-cell">
                                                                        <Text>{rowData.nome_arquivo}</Text>
                                                                        {rowData.anexado_equipe &&
                                                                            <Tag
                                                                                color="blue"
                                                                                style={{fontSize: "14px"}}>Anexado pela comissão
                                                                            </Tag>
                                                                        }
                                                                    </td>
                                                                    <td className="ant-table-cell">
                                                                        <Text>{rowData.enviado_em}</Text>
                                                                    </td>
                                                                    <td className="ant-table-cell">
                                                                        <a href={rowData.url}
                                                                            target='_blank'>
                                                                                Visualizar
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </Panel>
                                                {inscricao.recurso &&
                                                    <Panel header="Recurso" key="3">
                                                        <Space
                                                            direction="vertical"
                                                            width="100%">
                                                            <Text strong>Situação:</Text>
                                                            <Text>{inscricao.recurso.situacao}</Text>
                                                            <Text strong>Argumentação:</Text>
                                                            <Text style={{ textAlign: 'justify'}}>
                                                                {inscricao.recurso.argumentacao}
                                                            </Text>
                                                            {inscricao.recurso.parecer &&
                                                                <>
                                                                    <Text strong>Parecer:</Text>
                                                                    <Text style={{ textAlign: 'justify'}}>
                                                                        {inscricao.recurso.parecer}
                                                                    </Text>
                                                                </>
                                                            }
                                                            {inscricao.recurso_anexos &&
                                                                <table style={{width: "100%"}}>
                                                                    <thead className="ant-table-thead">
                                                                        <tr>
                                                                            <th className="ant-table-cell">Anexo</th>
                                                                            <th className="ant-table-cell">Enviado Em</th>
                                                                            <th className="ant-table-cell">Ação</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="ant-table-tbody">
                                                                        {inscricao.recurso_anexos && inscricao.recurso_anexos.map(rowData =>
                                                                            <tr
                                                                                key={rowData.key}
                                                                                className="ant-table-row ant-table-row-level-0">
                                                                                <td className="ant-table-cell">
                                                                                    <Text>{rowData.nome_arquivo}</Text>
                                                                                </td>
                                                                                <td className="ant-table-cell">
                                                                                    <Text>{rowData.enviado_em}</Text>
                                                                                </td>
                                                                                <td className="ant-table-cell">
                                                                                    <a href={rowData.url}
                                                                                        target='_blank'>
                                                                                            Visualizar
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            }
                                                        </Space>
                                                    </Panel>
                                                }
                                            </Collapse>
                                        </>
                                    )}
                                </Space>
                            )}
                        <div
                            style={{width: "100%", minHeight: "30px"}}>
                        </div>
                        </Space>

                    </Content>
            </Layout>

        </LayoutSite>
    )
  }
}

export default withRouter(
  connect(
    state=>({
      aluno: state.aluno})
  )(windowSize(InscricaoDetalhe)));