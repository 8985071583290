import React from 'react';
import { SelectOutlined, FormOutlined } from '@ant-design/icons';
import { 
    Card,
    Space,
    Button,
    Tag,
    Divider,
    Typography,
    message} from 'antd';

import axios from 'axios';

const { Text } = Typography;



class EditalCard extends React.Component {

    constructor(props){        
        super(props);     
        this.state = {
            recurso_realizado: false,
            inscricao_ref: '',
        }

        this.getSituacaoRecurso = this.getSituacaoRecurso.bind(this);
    }  

    getSituacaoRecurso(){
        const { auth_token } = this.props;
        const edital_ref = this.props.edital.key;
        
        axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
    

        if (auth_token.length) {        
            
            var qs = require('qs');
    
            const config = {
                "Content-Type": "application/x-www-form-urlencoded"
            }
            const requestBody = qs.stringify({
                edital_ref, auth_token
            }); 
    
            this.setState({recurso_realizado: false, inscricao_ref: ''}, ()=>{
              axios.post(process.env.REACT_APP_API_URL
                + 'situacaoRecurso',
                requestBody,
                config)
                .then(res => {           
                    if (res.data.sucesso){      
                        this.setState({
                            recurso_realizado: res.data.recurso_realizado,
                            inscricao_ref: res.data.inscricao_ref,
                        });                   
                    } 
                })
                .catch((error) => {              
                  message.error('Falha na conexão, verifique sua conexão com a Internet e tente recarregar a página.');                       
                });
            });       
          
        } 
    }

    componentDidMount(){
        if (this.props.auth_token.length)
            this.getSituacaoRecurso();
    }

    render(){
       
        const windowWidth = this.props.windowWidth;
        const { recurso_realizado, inscricao_ref } = this.state;
        const { edital, inscricao_situacao, inscricao_recurso, inscricao_edital_numero,
            inscricao_numero_ref, aluno_campus_ref } = this.props;

        const directionCronograma = (windowWidth < 640) ? "vertical" : "horizontal";

        return (   
            <Card 
                title={
                    <div>
                        <Text strong style={{fontSize: "16px"}}>Edital {edital.numero}</Text>
                        {(edital.inscricoes_abertas) && 
                            <Tag 
                                color="geekblue"
                                style={{marginLeft: "10px", fontSize: "14px"}}> Inscrições abertas para os alunos do Campus!</Tag>
                        }
                    </div>} 
                style={{ 
                    width: "100%", 
                    marginTop: "10px",
                    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)' }}
                headStyle={{ paddingLeft: "16px", paddingRight: "16px" }}
                bodyStyle={{ padding: "16px"}}>
                <Space 
                    direction="vertical"
                    style={{width: "100%"}}>

                    <Text strong style={{fontSize: "16px", color: "#0000005c"}}>Campus</Text>
                    <Text strong>{edital.campus_nome}</Text>                    

                    <Text strong style={{fontSize: "16px", color: "#0000005c"}}>Auxílios Ofertados</Text>

                    {(windowWidth < 640) ? (
                        <table style={{width: "100%"}}>
                            <thead className="ant-table-thead">
                                <tr>
                                    <th className="ant-table-cell">Descrição do auxílio</th> 
                                </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                                {edital.auxilios.map(rowData =>
                                <tr 
                                    key={rowData.key}
                                    className="ant-table-row ant-table-row-level-0">
                                    <td className="ant-table-cell">
                                        <Space
                                            direction="vertical"
                                            width="100%">
                                            <Text strong>{rowData.nome}</Text>
                                            <Text>{rowData.descricao}</Text>
                                            <Space
                                                direction="horizontal"
                                                width="100%">                                            
                                                <Text strong>Vagas:</Text>
                                                <Text>{rowData.vagas}</Text>                                            
                                                <Text strong>Valor:</Text>
                                                <Text>{rowData.valor}</Text>                                                              
                                            </Space>
                                        </Space>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    ):(
                        <table style={{width: "100%"}}>
                            <thead className="ant-table-thead">
                                <tr>
                                    <th className="ant-table-cell">Descrição do Auxílio</th>                            
                                    <th className="ant-table-cell">Vagas</th>                            
                                    <th className="ant-table-cell">Valor</th>  
                                </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                                {edital.auxilios.map(rowData =>
                                <tr 
                                    key={rowData.key}
                                    className="ant-table-row ant-table-row-level-0">
                                    <td className="ant-table-cell">
                                        <Text strong style={{display: "block"}}>{rowData.nome}</Text>
                                        <Text>{rowData.descricao}</Text>
                                    </td>
                                    <td className="ant-table-cell">{rowData.vagas}</td>
                                    <td className="ant-table-cell">{rowData.valor}</td>
                                </tr>                                
                                )}
                            </tbody>
                        </table>
                    )}                   

                    <Divider orientation="left">
                        <Text strong style={{fontSize: "16px", color: "#0000005c"}}>Cronograma</Text>
                    </Divider>

                    <Space
                        direction={directionCronograma}
                        width="100%">
                        <Text>Inscrições:</Text>
                        <Text strong style={{color: "#1c4311"}}>{edital.inscricoes}</Text>
                        {(inscricao_edital_numero !== undefined
                            && edital.inscricoes_abertas 
                            && (inscricao_situacao === 0) 
                            && (inscricao_edital_numero === edital.numero)) &&
                            <Button 
                                onClick={()=>this.props.onContinuarInscricaoClick()}
                                type="primary" 
                                icon={<FormOutlined />}>
                                Continuar inscrição
                            </Button>
                        }
                        {(edital.inscricoes_abertas 
                            && (inscricao_edital_numero === undefined || inscricao_edital_numero === null)
                            && aluno_campus_ref === 0) &&
                            <Button 
                                onClick={()=>this.props.onInscricaoClick(edital)}
                                type="primary" 
                                icon={<SelectOutlined />}>
                                Fazer inscrição
                            </Button>
                        }     
                        {(edital.inscricoes_abertas 
                            && (inscricao_edital_numero === undefined || inscricao_edital_numero === null)
                            && aluno_campus_ref === edital.campus_ref) &&
                            <Button 
                                onClick={()=>this.props.onInscricaoClick(edital)}
                                type="primary" 
                                icon={<SelectOutlined />}>
                                Fazer inscrição
                            </Button>
                        } 
                        {(edital.inscricoes_abertas 
                            && inscricao_numero_ref !== null
                            && inscricao_edital_numero !== edital.numero
                            && aluno_campus_ref === edital.campus_ref) &&
                            <Button 
                                onClick={()=>this.props.onInscricaoClick(edital)}
                                type="primary" 
                                icon={<SelectOutlined />}>
                                Fazer inscrição
                            </Button>
                        }        
                        {((inscricao_edital_numero !== undefined || inscricao_edital_numero !== null)
                            && edital.inscricoes_abertas 
                            && (inscricao_edital_numero === edital.numero)   
                            && (aluno_campus_ref === edital.campus_ref)                         
                            && (inscricao_situacao === 4)) && 
                            <Button 
                                onClick={()=>this.props.onInscricaoClick(edital)}
                                type="primary" 
                                icon={<SelectOutlined />}>
                                Fazer inscrição
                            </Button>
                        }                                      
                    </Space>                    
                    <Space
                        direction={directionCronograma}
                        width="100%">
                        <Text>Validação das Inscrições:</Text>
                        <Text strong style={{color: "#1c4311"}}>{edital.validacao_inscricoes}</Text>
                    </Space>
                    {(edital.classificacao==="barema_entrevista") &&
                        <Space
                            direction={directionCronograma}
                            width="100%">
                            <Text>Entrevistas:</Text>
                            <Text strong style={{color: "#1c4311"}}>{edital.entrevistas}</Text>
                        </Space>
                    }
                    <Space
                        direction={directionCronograma}
                        width="100%">
                        <Text>Resultado Preliminar:</Text>
                        <Text strong style={{color: "#1c4311"}}>{edital.resultado_preliminar}</Text>
                    </Space>
                    <Space
                        direction={directionCronograma}
                        width="100%">
                        <Text>Recursos:</Text>                        
                        <Text strong style={{color: "#1c4311"}}>{edital.recursos}</Text>
                        {(edital.recursos_abertos
                            && recurso_realizado
                            && (inscricao_ref.length > 0)) && 
                            <Tag 
                                color="geekblue"
                                style={{marginLeft: "10px", fontSize: "14px"}}> Você já enviou o recurso! Aguarde a publicação do resultado.</Tag>
                        }
                        {(edital.recursos_abertos 
                            && !recurso_realizado
                            && aluno_campus_ref === 0) &&
                            <Button 
                                onClick={()=>this.props.onRecursoClick(edital, inscricao_ref)}
                                type="primary" 
                                icon={<SelectOutlined />}>
                                Fazer recurso
                            </Button>
                        }
                        {(edital.recursos_abertos
                            && !recurso_realizado
                            && (inscricao_ref.length > 0)) &&
                            (<Button 
                                onClick={()=>this.props.onRecursoClick(edital, inscricao_ref)}
                                type="primary" 
                                icon={<SelectOutlined />}>
                                Fazer recurso
                            </Button>)
                        }   
                    </Space>
                    <Space
                        direction={directionCronograma}
                        width="100%">
                        <Text>Resultado Final:</Text>
                        <Text strong style={{color: "#1c4311"}}>{edital.resultado_final}</Text>
                    </Space>
                    <Divider orientation="left">
                        <Text strong style={{fontSize: "16px", color: "#0000005c"}}>Publicações</Text>
                    </Divider>
                    {edital.publicacoes.map((publicacao, idx) =>                         
                        <Text key={idx}>
                            <a href={publicacao.href}>Baixar</a> - {publicacao.data} - {publicacao.descricao}                              
                        </Text>                        
                    )}
                </Space>
            </Card>
        );
    }
}

export default EditalCard;