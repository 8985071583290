import React from 'react';

import {
  Layout,
  Space,
  Breadcrumb,
  Typography,
  Button,
  Card,
  Upload,
  Skeleton,
  message,
  Tag
} from 'antd';

import axios from 'axios';

import windowSize from 'react-window-size';
import LayoutSite from '../../componentes/LayoutSite';

import { UploadOutlined } from '@ant-design/icons';


const { Content } = Layout;
const { Title, Text } = Typography;


class Anexos extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      processando: false,
      messageError: [],
      fileList: [],
      documentos: [],
    }

    this.carregaAnexos = this.carregaAnexos.bind(this);

  }

  componentWillUnmount(){
    this._mounted = false;
    this._clickedOnce = false;
  }

  componentDidMount(){
    this._mounted = true;
    this._clickedOnce = false;
    this.carregaAnexos();
  }

  carregaAnexos(){
    const { auth_token, inscricao_numero_ref } = this.props.aluno;

    var qs = require('qs');

    const config = {
        "Content-Type": "application/x-www-form-urlencoded"
    }
    const requestBody = qs.stringify({
        auth_token,
        inscricao_numero_ref
    });

    this.setState({processando: true, familiares: []}, ()=>{
      axios.post(process.env.REACT_APP_API_URL
        + 'listaDocumentacaoObrigatoria',
        requestBody,
        config)
        .then(res => {
            if (res.data.sucesso){
                let documentos = [];
                  res.data.documentos.map(documento=>{
                      documentos[documento.key] = documento;
                  });

                this.setState({
                    documentos,
                });
            }
        })
        .catch((error) => {
          message.error('Falha na conexão, verifique sua conexão com a Internet e tente recarregar a página.');
        })
        .finally(() => {
          this.setState({ processando: false });
        });
    });
  }

  handleChange = (info, ref) => {
    let documentos = this.state.documentos;
    let fileList = [...info.fileList];

    if (info.file.status === 'done') {
        message.success(`${info.file.name} enviado com sucesso!`);
    } else if (info.file.status === 'error') {
        if (info.file.error.status === 413)
          message.error('Arquivo muito grande para ser enviado.');
        else if (info.file.response !== undefined)
          message.error(`${info.file.response.message}`);
        else
          message.error('Falha no envio. Verifique sua conexão com a Internet.');

        //remove o último arquivo com erro, para não confundir o usuário
        fileList = fileList.slice(0, fileList.length-1);
    }

    fileList = fileList.map(file => {
      if (file.response) {
        file.type = 'application/pdf';
        file.url = file.response.url;
      }
      return file;
    });

    documentos[ref].fileList = fileList;

    this.setState({ documentos });
  };

  handleRemove = info => {
    if (this._clickedOnce)
      return false;

    this._clickedOnce = true;
    return new Promise((resolve, reject) => {
      const { auth_token, inscricao_numero_ref } = this.props.aluno;

      var qs = require('qs');

      const config = {
          "Content-Type": "application/x-www-form-urlencoded"
      }
      const requestBody = qs.stringify({
          auth_token,
          inscricao_numero_ref,
          arquivo_ref: (info.ref_disk !== undefined) ? info.ref_disk : info.response.ref_disk,
      });

      axios.post(process.env.REACT_APP_API_URL
        + 'removeDocumento',
        requestBody,
        config)
        .then(res => {
            if (res.data.sucesso){
              message.success('Arquivo removido com sucesso.');
              resolve(true);
            }
        })
        .catch((error) => {
          message.error('Falha na conexão, verifique sua conexão com a Internet e tente novamente.');
          this._clickedOnce = false;
          reject(true);
        })
        .finally(()=>{
          this._clickedOnce = false;
        });
    });

  };

  render() {
    const {processando, documentos } = this.state;

    const { aluno } = this.props;

    const windowWidth = this.props.windowWidth;

    return (

        <LayoutSite>

            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Assistência Estudantil</Breadcrumb.Item>
              <Breadcrumb.Item>Inscrição atual</Breadcrumb.Item>
              <Breadcrumb.Item>4. Anexos da documentação</Breadcrumb.Item>

            </Breadcrumb>

            <Layout className="site-layout-background"
              style={{
                marginTop: "24px",
                padding: '12px 0' }}>
                  <Content
                    style={{ padding: '0 12px', minHeight: 280 }}>
                      <Space
                        direction="vertical"
                        style={{width: "100%"}}>
                          <Button
                            type="link"
                            onClick={()=>this.props.onVoltarClick()}
                            style={{padding: "0"}}>Voltar</Button>
                          <Title level={4}>Anexos da documentação</Title>

                          <div>
                            <Space
                              direction="vertical"
                              style={{width: "100%"}}>
                              <Text>Anexe os arquivos, conforme documentação solicitada abaixo. Os arquivos deverão estar no formato de imagem (JPG ou PNG) ou documento eletrônico (PDF), com tamanho máximo de 5 MB por arquivo. <Text strong>Lembre-se de verificar antes se os arquivos estão legíveis, pois arquivos ilegíveis não serão considerados.</Text></Text>

                              {processando ? (
                                  <div>
                                  <Text>Carregando ...</Text>
                                  <Skeleton active />
                                  </div>
                              ) : (
                                <div>
                                {(windowWidth < 640) ? (
                                  <div>
                                    {Object.values(documentos).map(documento =>
                                      <Card
                                          key={documento.key}
                                          type="inner" title={documento.nome}
                                          bodyStyle={{padding: "4px"}}
                                          style={{marginBottom: "10px"}}>
                                          <Space
                                              direction="vertical"
                                              style={{width: "100%"}}>
                                              {documento.opcional ? (
                                                <Tag color="green">Envio opcional</Tag>
                                              ) : (
                                                <Tag color="geekblue">Envio obrigatório</Tag>
                                              )}
                                              <Text>
                                                  Descrição: {documento.descricao}
                                              </Text>
                                              <Upload
                                                action={process.env.REACT_APP_API_URL+'uploadDocumento'}
                                                listType="picture"
                                                headers={{
                                                  'AUTH-TOKEN': aluno.auth_token,
                                                  'INSCRICAO-NUMERO-REF': aluno.inscricao_numero_ref,
                                                  'DOCUMENTO-REF': documento.key,
                                                  }}
                                                multiple={true}
                                                accept=".jpeg,.jpg,.png,.pdf"
                                                onChange={(e)=>this.handleChange(e, documento.key)}
                                                onRemove={this.handleRemove}
                                                fileList={documento.fileList}>
                                                  <Button type="primary" icon={<UploadOutlined />}>Enviar arquivo</Button>
                                              </Upload>
                                          </Space>
                                      </Card>
                                      )}
                                  </div>
                                ): (
                                  <Card title="Envio de arquivos">
                                    {Object.values(documentos).map(documento =>
                                      <Card
                                          key={documento.key}
                                          type="inner" title={documento.nome}
                                          style={{marginBottom: "10px"}}>
                                          <Space
                                              direction="vertical"
                                              style={{width: "100%"}}>
                                              {documento.opcional ? (
                                                <Tag color="green">Envio opcional</Tag>
                                              ) : (
                                                <Tag color="geekblue">Envio obrigatório</Tag>
                                              )}
                                              <Text>
                                                  Descrição: {documento.descricao}
                                              </Text>
                                              <Upload
                                                action={process.env.REACT_APP_API_URL+'uploadDocumento'}
                                                listType="picture"
                                                headers={{
                                                  'AUTH-TOKEN': aluno.auth_token,
                                                  'INSCRICAO-NUMERO-REF': aluno.inscricao_numero_ref,
                                                  'DOCUMENTO-REF': documento.key,
                                                  }}
                                                multiple={true}
                                                accept=".jpeg,.jpg,.png,.pdf"
                                                onChange={(e)=>this.handleChange(e, documento.key)}
                                                onRemove={this.handleRemove}
                                                fileList={documento.fileList}>
                                                  <Button type="primary" icon={<UploadOutlined />}>Enviar arquivo</Button>
                                              </Upload>
                                          </Space>
                                      </Card>
                                      )}
                                  </Card>
                                  )}
                                  </div>
                                )}

                              <Button
                                type="default"
                                onClick={()=>this.props.onVoltarClick()}>
                                  Voltar</Button>
                            </Space>

                          </div>

                        <div
                          style={{width: "100%", minHeight: "30px"}}>

                        </div>
                      </Space>

                  </Content>
            </Layout>

        </LayoutSite>
    );
  }
}

export default windowSize(Anexos);