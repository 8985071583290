import React from 'react';

import {
  Layout,
  Space,
  Breadcrumb,
  Typography,
  Input,
  Button,
  Card,
  Tag,
  Upload,
  message} from 'antd';


import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import windowSize from 'react-window-size';
import LayoutSite from '../../componentes/LayoutSite';

import { UploadOutlined } from '@ant-design/icons';

import axios from 'axios';

const { Content } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;

class NovoRecurso extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      processando: false,
      messageError: [],
      argumentacao: "",
      autorizado: (props.aluno.auth_token.length && props.edital_selecionado !== undefined),
      fileList: [],
    }

    if (!this.state.autorizado)
      this.props.history.push('/');

    this.onChangeArgumentacao = this.onChangeArgumentacao.bind(this);
    this.enviaRecursoClick = this.enviaRecursoClick.bind(this);
    this.carregaAnexos = this.carregaAnexos.bind(this);
  }

  componentWillUnmount(){
    this._mounted = false;
  }

  componentDidMount(){
    this._mounted = true;
    this.carregaAnexos();
  }

  onChangeArgumentacao(e){
    this.setState({
      argumentacao: e.target.value,
    })
  }

  handleChange = (info) => {
    let fileList = [...info.fileList];

    // if (info.file.status !== 'uploading') {
    //     console.log(info.file, info.fileList);
    // }

    if (info.file.status === 'done') {
        message.success(`${info.file.name} enviado com sucesso!`);
    } else if (info.file.status === 'error') {
        if (info.file.error.status === 413)
          message.error('Arquivo muito grande para ser enviado.');
        else if (info.file.response !== undefined)
          message.error(`${info.file.response.message}`);
        else
          message.error('Falha no envio. Verifique sua conexão com a Internet.');

        //remove o último arquivo com erro, para não confundir o usuário
        fileList = fileList.slice(0, fileList.length-1);
    }

    fileList = fileList.map(file => {
      if (file.response) {
        file.type = 'application/pdf';
        file.url = file.response.url;
      }
      return file;
    });

    this.setState({ fileList });
  };

  handleRemove = info => {
    if (this._clickedOnce)
      return false;

    this._clickedOnce = true;
    return new Promise((resolve, reject) => {
      const { auth_token, inscricao_numero_ref } = this.props.aluno;

      var qs = require('qs');

      const config = {
          "Content-Type": "application/x-www-form-urlencoded"
      }
      const requestBody = qs.stringify({
          auth_token,
          inscricao_numero_ref,
          arquivo_ref: (info.ref_disk !== undefined) ? info.ref_disk : info.response.ref_disk,
      });

      axios.post(process.env.REACT_APP_API_URL
        + 'removeDocumentoRecurso',
        requestBody,
        config)
        .then(res => {
            if (res.data.sucesso){
              message.success('Arquivo removido com sucesso.');
              resolve(true);
            } else {
              message.error(res.data.message);
              reject(true);
            }
        })
        .catch((error) => {
          message.error('Falha na conexão, verifique sua conexão com a Internet e tente novamente.');
          this._clickedOnce = false;
          reject(true);
        })
        .finally(()=>{
          this._clickedOnce = false;
        });
    });

  };

  enviaRecursoClick(){
    this.setState({processando: true}, ()=>{
        let qs = require('qs');

        const config = {
          "Content-Type": "application/x-www-form-urlencoded"
        }

        const { argumentacao } = this.state;
        const { auth_token, inscricao_numero_ref } = this.props.aluno;

        const requestBody = qs.stringify(
          { auth_token, inscricao_numero_ref, argumentacao }
        );

        axios.post(process.env.REACT_APP_API_URL
          + 'novoRecurso',
          requestBody,
          config)
          .then(res => {
            if (res.data.sucesso){
              this.props.dispatch({
                type: 'RECURSO_ENVIADO'
              });
              this.props.history.push("/");
            } else
              this.setState({messageError: res.data.msg});

          })
          .catch((error) => {
            message.error("Falha na comunicação. Verifique sua conexão com a Internet e tente novamente.");
          })
          .finally(()=>{
            if (this._mounted)
              this.setState({processando: false});
          });
      });
  }

  carregaAnexos(){
    const { auth_token, inscricao_numero_ref } = this.props.aluno;

    var qs = require('qs');

    const config = {
        "Content-Type": "application/x-www-form-urlencoded"
    }
    const requestBody = qs.stringify({
        auth_token,
        inscricao_numero_ref
    });

    this.setState({processando: true, familiares: []}, ()=>{
      axios.post(process.env.REACT_APP_API_URL
        + 'listaAnexosRecurso',
        requestBody,
        config)
        .then(res => {
            if (res.data.sucesso){
                const fileList = res.data.fileList;
                this.setState({
                    fileList,
                });
            }
        })
        .catch((error) => {
          message.error('Falha na conexão, verifique sua conexão com a Internet e tente recarregar a página.');
        })
        .finally(() => {
          this.setState({ processando: false });
        });
    });
  }

  render() {
    const { processando, messageError, argumentacao, autorizado, fileList } = this.state;
    const { aluno, edital_selecionado } = this.props;
    const { onChangeArgumentacao, enviaRecursoClick } = this;

    if (!autorizado)
      return null;

    return (

        <LayoutSite>

            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Assistência Estudantil</Breadcrumb.Item>
              <Breadcrumb.Item>Recurso</Breadcrumb.Item>
            </Breadcrumb>

            <Layout className="site-layout-background"
              style={{
                marginTop: "24px",
                padding: '12px 0' }}>
                  <Content
                    style={{ padding: '0 12px', minHeight: 280 }}>
                      <Space
                        direction="vertical"
                        style={{width: "100%"}}>
                          <Button
                                type="link"
                                onClick={()=>this.props.history.push("/")}
                                style={{padding: "0"}}>Início</Button>
                          <Title level={3}>Recurso - Edital {edital_selecionado.numero}</Title>

                          <div>
                            <Space
                              direction="vertical"
                              style={{width: "100%"}}>

                              <Text>Para enviar o recurso, preencha abaixo a sua argumentação contra o resultado preliminar (conforme explícito em Edital). Lembrando que será permitido apenas um recurso por candidato e esse não poderá ser editado após o envio. </Text>
                              <Text>* Argumentação (máximo de 2000 caracteres):</Text>
                              <div>
                                <TextArea
                                    value={argumentacao}
                                    onChange={onChangeArgumentacao}
                                    rows={10}
                                    maxLength={2000}
                                    style={{resize: 'none'}}/>
                                {(argumentacao.length > 0) &&
                                <Text>{2000-argumentacao.length} caracteres restantes.</Text>
                                }
                              </div>
                            </Space>
                            <Space
                              direction="vertical"
                              style={{width: "100%", marginTop: "20px"}}>
                              <Card
                                  type="inner" title="Anexos"
                                  style={{marginBottom: "10px"}}>
                                  <Space
                                      direction="vertical"
                                      style={{width: "100%"}}>
                                      <Tag color="green">Envio opcional</Tag>
                                      <Text>
                                          Anexo de documentos que podem comprovar a sua argumentação. Os arquivos deverão estar no formato de imagem (JPG ou PNG) ou documento eletrônico (PDF), com tamanho máximo de 5 MB por arquivo. <Text strong>Lembre-se de verificar antes se os arquivos estão legíveis, pois arquivos ilegíveis não serão considerados.</Text>
                                      </Text>
                                      <Upload
                                        action={process.env.REACT_APP_API_URL+'uploadDocumentoRecurso'}
                                        listType="picture"
                                        headers={{
                                          'AUTH-TOKEN': aluno.auth_token,
                                          'INSCRICAO-NUMERO-REF': aluno.inscricao_numero_ref,
                                          }}
                                        multiple={true}
                                        accept=".jpeg,.jpg,.png,.pdf"
                                        onChange={(e)=>this.handleChange(e)}
                                        onRemove={this.handleRemove}
                                        fileList={fileList}>
                                          <Button type="primary" icon={<UploadOutlined />}>Enviar arquivo</Button>
                                      </Upload>
                                  </Space>
                              </Card>
                            </Space>
                            {(messageError.length > 0) &&
                              <div className="alert-box">
                                <Text style={{display: "block"}}>Problema encontrado:</Text>
                                <Text>🔸 {messageError}</Text>
                              </div>
                            }
                            <Space
                                direction="horizontal"
                                style={{marginTop: "20px"}}>
                                <Button
                                  onClick={()=>this.props.history.push('/')}>Cancelar</Button>
                                {processando ? (
                                  <Button
                                    type="primary" loading>Aguarde ...</Button>
                                ):(
                                  <Button
                                    disabled={(!argumentacao.length)}
                                    onClick={enviaRecursoClick}
                                    type="primary">Enviar recurso</Button>
                                )}

                              </Space>

                          </div>

                        <div
                          style={{width: "100%", minHeight: "30px"}}>

                        </div>
                      </Space>

                  </Content>
            </Layout>

        </LayoutSite>
    );
  }
}

export default withRouter(
  connect(
    state=>({
      aluno: state.aluno,
      edital_selecionado: state.edital_selecionado})
  )(windowSize(NovoRecurso)));