import React from 'react';

import { 
  Layout, 
  Space, 
  Breadcrumb, 
  Skeleton, 
  Select, 
  Typography,
  Collapse,
  Alert,
  Button,
  message } from 'antd';

import { FormOutlined, YoutubeFilled } from '@ant-design/icons';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import windowSize from 'react-window-size';
import LayoutSite from '../../componentes/LayoutSite';
import EditalCard from './Componentes/EditalCard';

import axios from 'axios';

const { Content } = Layout;
const { Option } = Select;
const { Title, Text, Link } = Typography;
const { Panel } = Collapse;


class Inicio extends React.Component {

  state = {
    campus: "0",
    ano: "2024",
    processando: true,
    editais: [],
  }

  constructor(props){
    super(props);

    this.campusSelectChange = this.campusSelectChange.bind(this);
    this.anoSelectChange = this.anoSelectChange.bind(this);
    this.carregaEditais = this.carregaEditais.bind(this);
    this.inscricaoClick = this.inscricaoClick.bind(this);
    this.recursoClick = this.recursoClick.bind(this);
    this.setBotaoLoginRef = this.setBotaoLoginRef.bind(this);
    this.onContinuarInscricaoClick = this.onContinuarInscricaoClick.bind(this);

    this.botaoLoginRef = undefined;
  }

  componentWillMount(){
    this.carregaEditais();
  }

  setBotaoLoginRef(e){
    this.botaoLoginRef = e;
  }

  inscricaoClick(edital_ref){
    const { aluno } = this.props;
    const { dispatch, history } = this.props;

    if (!aluno.auth_token.length){
      message.warn('É necessário estar logado para prosseguir.');                     
      this.botaoLoginRef.click();
    } else {
      dispatch({type: 'SELECIONA_EDITAL', edital_selecionado: edital_ref});
      history.push('/novaInscricao');
    }
  }

  recursoClick(edital_ref, inscricao_ref){
    const { aluno } = this.props;
    const { dispatch, history } = this.props;

    if (!aluno.auth_token.length){
      message.warn('É necessário estar logado para prosseguir.');                     
      this.botaoLoginRef.click();
    } else {
      dispatch({type: 'NOVO_RECURSO', edital_selecionado: edital_ref, 
        inscricao_numero_ref: inscricao_ref});
      history.push('/novoRecurso');
    }
  }

  campusSelectChange(value){
    this.setState({campus: value}, 
      ()=>this.carregaEditais());
  }

  anoSelectChange(value){
    this.setState({ano: value},
      ()=>this.carregaEditais());
  }  

  carregaEditais(){
    const {campus, ano} = this.state;

    axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

    if (campus.length && ano.length) {        
            
        

        var qs = require('qs');

        const config = {
            "Content-Type": "application/x-www-form-urlencoded"
        }
        const requestBody = qs.stringify({
            campus, ano
        }); 

        this.setState({processando: true, editais: []}, ()=>{
          axios.post(process.env.REACT_APP_API_URL
            + 'listaEditais',
            requestBody,
            config)
            .then(res => {           
                if (res.data.sucesso){      
                    this.setState({
                        editais: res.data.editais,
                    });                   
                } 
            })
            .catch((error) => {              
              message.error('Falha na conexão, verifique sua conexão com a Internet e tente recarregar a página.');                       
            })
            .finally(() => {
              this.setState({ processando: false });
            });
        });       
      
    } 
  }

  componentDidMount(){
      this.carregaEditais();
  }

  onContinuarInscricaoClick(){
    this.props.history.push('/inscricao');
  }

  render() {
    const { processando, editais } = this.state;
    const windowWidth = this.props.windowWidth;

    const { inscricaoClick, recursoClick, setBotaoLoginRef, 
      onContinuarInscricaoClick } = this;
    const { aluno } = this.props;

    return (

        <LayoutSite
          botaoLoginRef={setBotaoLoginRef}>
          
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Assistência Estudantil</Breadcrumb.Item>
              <Breadcrumb.Item>Editais</Breadcrumb.Item>
            </Breadcrumb>          

            <Layout className="site-layout-background" 
              style={{ 
                marginTop: "24px",
                padding: '12px 0' }}>            
                  <Content 
                    style={{ padding: '0 12px', minHeight: 280 }}>
                      <Space 
                        direction="vertical"
                        style={{width: "100%"}}>
                          {(aluno.inscricao_situacao === 0) && (
                            <div>
                              <Alert
                                message="Atenção"
                                type="warning"
                                description={
                                  <div style={{display: "block"}}>
                                      <Text strong>Você possui uma inscrição que ainda não foi finalizada.</Text>
                                      <Text style={{display: "block"}}>Número da inscrição: {aluno.inscricao_numero}</Text>
                                      <Text style={{display: "block"}}>Edital: {aluno.inscricao_edital_numero}</Text>
                                      <Text style={{display: "block"}}>Campus: {aluno.campus_nome}</Text>
                                      
                                      <Button 
                                            onClick={onContinuarInscricaoClick}
                                            type="primary" 
                                            style={{marginTop: "10px", display: "block"}}
                                            icon={<FormOutlined />}>
                                            Continuar inscrição
                                      </Button>
                                  </div>
                                }
                                showIcon/>
                              
                            </div> 
                          )}
                          {(aluno.inscricao_situacao === 1) && (
                            <div>
                              <Alert
                                message="Inscrição finalizada!"
                                type="success"
                                description={
                                  <div style={{display: "block"}}>
                                      <Text strong>Acompanhe as publicações do Edital para maiores informações.</Text>
                                      <Text style={{display: "block"}}>Número da inscrição: {aluno.inscricao_numero}</Text>
                                      <Text style={{display: "block"}}>Edital: {aluno.inscricao_edital_numero}</Text>
                                      <Text style={{display: "block"}}>Campus: {aluno.campus_nome}</Text>                                      
                                  </div>
                                }
                                showIcon/>
                              
                            </div> 
                          )}           

                          {(aluno.msg_sucesso !== undefined) && (
                            <div>
                              <Alert
                                message="Alerta"
                                type="success"
                                description={aluno.msg_sucesso}
                                showIcon
                                closable={true}/>                              
                            </div> 
                          )}               

                          <Title level={3}>Editais</Title>
                          <Text>Aqui você poderá inscrever-se e acompanhar os editais de Assistência Estudantil do IFSertãoPE. Use o filtro para refinar a listagem.</Text>
                          <Link 
                            href="https://www.youtube.com/watch?v=zNDxk8So3hM" 
                            target="_blank"
                            style={{ color: "red" }}>Clique aqui para aprender como fazer a sua inscrição!<YoutubeFilled style={{ fontSize: "24px", marginLeft: "5px" }}/></Link>
                          <Collapse defaultActiveKey={['1']}>
                            <Panel header="Filtro" key="1">
                              <Space 
                                direction="vertical"
                                style={{width: "100%"}}>
                                <Text>Campus:</Text>
                                <Select                                 
                                  defaultValue="0" 
                                  style={{ width: 230 }} 
                                  onChange={this.campusSelectChange}>
                                  <Option value="0">Todos</Option>
                                  <Option value="2">Petrolina</Option>
                                  <Option value="3">Petrolina Zona Rural</Option>
                                  <Option value="4">Floresta</Option>
                                  <Option value="5">Salgueiro</Option>
                                  <Option value="6">Ouricuri</Option>
                                  <Option value="7">Serra Talhada</Option>
                                  <Option value="8">Santa Maria da Boa Vista</Option>                        
                                </Select>
                                <Text>Ano:</Text>
                                <Select                                 
                                  defaultValue="2024" 
                                  style={{ width: 80 }} 
                                  onChange={this.anoSelectChange}>
                                  <Option value="2024">2024</Option>
                                  <Option value="2023">2023</Option>
                                  <Option value="2022">2022</Option>
                                  <Option value="2021">2021</Option>
                                </Select>
                              </Space>
                            </Panel>
                          </Collapse>              

                          {processando ? (
                            <div>
                              <Text>Carregando ...</Text>
                              <Skeleton active />                             
                            </div>
                          ) : (
                            <div>
                              <Text>{editais.length} editais encontrados.</Text>

                              {editais.map(edital=>
                                <EditalCard 
                                  key={edital.key}
                                  auth_token={aluno.auth_token}
                                  edital={edital}                     
                                  aluno_campus_ref={aluno.campus_ref}           
                                  inscricao_edital_numero={aluno.inscricao_edital_numero}
                                  inscricao_situacao={aluno.inscricao_situacao}
                                  inscricao_recurso={aluno.inscricao_recurso}
                                  inscricao_numero_ref={aluno.inscricao_numero_ref}
                                  onInscricaoClick={inscricaoClick}
                                  onRecursoClick={recursoClick}
                                  onContinuarInscricaoClick={onContinuarInscricaoClick}
                                  windowWidth={windowWidth}/>
                              )}
                            </div>
                          )}                         
                        <div
                          style={{width: "100%", minHeight: "30px"}}>

                        </div>
                      </Space>
                      
                  </Content>
            </Layout>
          
        </LayoutSite>        
    );
  }
}

export default withRouter(
    connect(
      state=>({aluno: state.aluno})
    )(windowSize(Inicio)));