import React from 'react';

import {
  Layout,
  Space,
  Breadcrumb,
  Typography,
  Button,
  Skeleton,
  message} from 'antd';

import { SelectOutlined, FormOutlined } from '@ant-design/icons';

import axios from 'axios';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import windowSize from 'react-window-size';
import LayoutSite from '../../componentes/LayoutSite';
import InscricaoDetalhe from './InscricaoDetalhe';


const { Content } = Layout;
const { Title, Text } = Typography;

class ListagemInscricao extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      processando: false,/*
      messageError: '',
      ciencia_inscricao: false, */
      etapa: 0,
      inscricao_ref: '',
      inscricoes: [],
      autorizado: (props.aluno.auth_token.length),
    }

    this.voltarClick = this.voltarClick.bind(this);
    // this.atualizaDadosPessoais = this.atualizaDadosPessoais.bind(this);
    // this.confirmaCancelaInscricao = this.confirmaCancelaInscricao.bind(this);
    // this.finalizaInscricao = this.finalizaInscricao.bind(this);
    // this.etapaAnexos = this.etapaAnexos.bind(this);

    if (!this.state.autorizado)
      this.props.history.push('/');

  }

  componentWillUnmount(){
    this._mounted = false;
  }

  componentDidMount(){
    this._mounted = true;
    window.scrollTo(0, 0);
    this.carregaInscricoes();
  }

  voltarClick(){
    this.setState({
      etapa: 0,
    }, ()=>{
      window.scrollTo(0, 0);
    });
  }

  carregaInscricoes(){
    const { auth_token } = this.props.aluno;

    var qs = require('qs');

    const config = {
        "Content-Type": "application/x-www-form-urlencoded"
    }
    const requestBody = qs.stringify({
        auth_token
    });

    this.setState({processando: true, familiares: []}, ()=>{
      axios.post(process.env.REACT_APP_API_URL
        + 'listaInscricoesUsuario',
        requestBody,
        config)
        .then(res => {
            if (res.data.sucesso){

                let inscricoes = [];
                res.data.inscricoes.map(componente=>{
                    inscricoes[componente.key] = componente;
                });

                this.setState({
                    inscricoes,
                });
            }
        })
        .catch((error) => {
          message.error('Falha na conexão, verifique sua conexão com a Internet e tente recarregar a página.');
        })
        .finally(() => {
          this.setState({ processando: false });
        });
    });
  }

  render() {
    const { autorizado, etapa, processando,
      inscricao_ref, inscricoes } = this.state;
    const { aluno } = this.props;
    const { voltarClick, atualizaDadosPessoais,
      etapaAnexos } = this;
    const windowWidth = this.props.windowWidth;

    let etapaRender = null;

    if (!autorizado)
      return etapaRender;

    switch (etapa){
      case 1:
          etapaRender = <InscricaoDetalhe
            aluno={aluno}
            inscricao={inscricao_ref}
            inscricao_numero={inscricoes[inscricao_ref].numero}
            onVoltarClick={voltarClick} />;
          break;
      default:
        etapaRender =
          <LayoutSite>

              <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item href="./">Assistência Estudantil</Breadcrumb.Item>
                <Breadcrumb.Item>Minhas inscrições</Breadcrumb.Item>
              </Breadcrumb>

              <Layout className="site-layout-background"
                style={{
                  marginTop: "24px",
                  padding: '12px 0' }}>
                    <Content
                      style={{ padding: '0 12px', minHeight: 280 }}>
                        <Space
                          direction="vertical"
                          style={{width: "100%"}}>
                            <Button
                              type="link"
                              onClick={()=>this.props.history.push("/")}
                              style={{padding: "0"}}>Início</Button>
                            <Title level={3}>Minhas inscrições</Title>
                            <div>
                              <Space
                                direction="vertical"
                                style={{width: "100%"}}>
                                  <Text>Aqui você encontra todas as inscrições que já realizou.</Text>
                                  {processando ? (
                                      <div>
                                      <Text>Carregando ...</Text>
                                      <Skeleton active />
                                      </div>
                                  ) : (
                                      <div>
                                        {(windowWidth < 640) ? (
                                          <table style={{width: "100%"}}>
                                              <thead className="ant-table-thead">
                                                  <tr>
                                                      <th className="ant-table-cell">Inscrição</th>
                                                  </tr>
                                              </thead>
                                              <tbody className="ant-table-tbody">
                                                  {Object.values(inscricoes).map(rowData =>
                                                  <tr
                                                      key={rowData.key}
                                                      className="ant-table-row ant-table-row-level-0">
                                                      <td className="ant-table-cell">
                                                          <Space
                                                              direction="vertical"
                                                              width="100%">
                                                              <Space
                                                                  direction="horizontal"
                                                                  width="100%">
                                                                  <Text strong>Número:</Text>
                                                                  <Text>{rowData.numero}</Text>
                                                              </Space>
                                                              <Space
                                                                  direction="horizontal"
                                                                  width="100%">
                                                                  <Text strong>Edital:</Text>
                                                                  <Text>{rowData.edital}</Text>
                                                              </Space>
                                                              <Space
                                                                  direction="horizontal"
                                                                  width="100%">
                                                                  <Text strong>Prazo de inscrição:</Text>
                                                                  <Text>{rowData.inscricao_ate}</Text>
                                                              </Space>
                                                              <Space
                                                                  direction="horizontal"
                                                                  width="100%">
                                                                  <Text strong>Situação:</Text>
                                                                  <Text>{rowData.situacao}</Text>
                                                              </Space>
                                                              {(rowData.situacao === "Iniciada" &&
                                                                !rowData.expirada) ? (
                                                                <Button
                                                                    type="primary"
                                                                    href='./inscricao'
                                                                    icon={<FormOutlined />}>
                                                                    Continuar
                                                                </Button>
                                                              ) : (
                                                                <Button
                                                                    type="primary"
                                                                    onClick={()=>this.setState({
                                                                      etapa: 1,
                                                                      inscricao_ref: rowData.key,
                                                                      messageError: ''})}
                                                                    icon={<SelectOutlined />}>
                                                                    Visualizar
                                                                </Button>
                                                              )}
                                                          </Space>
                                                      </td>
                                                  </tr>
                                                  )}
                                              </tbody>
                                          </table>
                                        ) : (
                                          <table style={{width: "100%"}}>
                                              <thead className="ant-table-thead">
                                                  <tr>
                                                      <th className="ant-table-cell">Inscrição</th>
                                                      <th className="ant-table-cell">Edital</th>
                                                      <th className="ant-table-cell">Data de inscrição</th>
                                                      <th className="ant-table-cell">Situação</th>
                                                      <th className="ant-table-cell">Ação</th>
                                                  </tr>
                                              </thead>
                                              <tbody className="ant-table-tbody">
                                                {Object.values(inscricoes).map(rowData =>
                                                  <tr
                                                      key={rowData.key}
                                                      className="ant-table-row ant-table-row-level-0">
                                                      <td className="ant-table-cell">
                                                          <Text strong style={{display: "block"}}>{rowData.numero}</Text>
                                                      </td>
                                                      <td className="ant-table-cell">{rowData.edital}</td>
                                                      <td className="ant-table-cell">{rowData.inscricao_ate}</td>
                                                      <td className="ant-table-cell">{rowData.situacao}</td>
                                                      <td className="ant-table-cell">
                                                        {(rowData.situacao === "Iniciada" &&
                                                          !rowData.expirada) ? (
                                                            <Button
                                                              type="primary"
                                                              href='./inscricao'
                                                              icon={<FormOutlined />}>
                                                              Continuar
                                                          </Button>
                                                        ) : (
                                                          <Button
                                                              type="primary"
                                                              onClick={()=>this.setState({
                                                                etapa: 1,
                                                                inscricao_ref: rowData.key,
                                                                messageError: ''})}
                                                              icon={<SelectOutlined />}>
                                                              Visualizar                                                      </Button>
                                                        )}
                                                      </td>
                                                  </tr>
                                                  )}
                                              </tbody>
                                          </table>
                                        )}
                                      </div>
                                  )}
                              </Space>

                            </div>

                          <div
                            style={{width: "100%", minHeight: "30px"}}>

                          </div>
                        </Space>

                    </Content>
              </Layout>

          </LayoutSite>;
    }

    return etapaRender;
  }
}

export default withRouter(
  connect(
    state=>({
      aluno: state.aluno})
  )(windowSize(ListagemInscricao)));