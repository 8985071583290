import React from 'react';

import { 
  Layout, 
  Space, 
  Breadcrumb, 
  Typography,
  Radio,
  Input,
  Button,
  Progress,
  Modal,
  Skeleton,
  message, 
  Checkbox, 
  Alert} from 'antd';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import axios from 'axios';

import windowSize from 'react-window-size';
import LayoutSite from '../../componentes/LayoutSite';


const { Content } = Layout;
const { TextArea } = Input;
const { Title, Text } = Typography;


class Questionario extends React.Component {  

  constructor(props){
    super(props);

    this.state = {
      processando: false,    
      messageError: [],
      questao: undefined,
      percentualProgresso: 0,
      respostaRadio: undefined, //questão objetiva de única escolha
      respostasCheckbox: [], //questão objetiva de múltiplas escolhas
      respostaDiscursiva: '',
      discursivaObjetivas: [], //algumas alternativas exigem uma resposta discursiva em conjunto
      habilitaProxima: false,
      habilitaAnterior: true,
    }

    this.onSelecionaRespostaRadio = this.onSelecionaRespostaRadio.bind(this);
    this.onSelecionaRespostaCheckbox = this.onSelecionaRespostaCheckbox.bind(this);
    this.onChangeDiscursiva = this.onChangeDiscursiva.bind(this);
    this.onChangeDiscursivaAlternativa = this.onChangeDiscursivaAlternativa.bind(this);
    this.questaoAnterior = this.questaoAnterior.bind(this);
    this.proximaQuestao = this.proximaQuestao.bind(this);
    this.errorMsg = this.errorMsg.bind(this);
  }

  componentWillUnmount(){
    this._mounted = false;
  }
  
  componentDidMount(){      
    this._mounted = true;

    this.buscaQuestao();
  }

  buscaQuestao(acao=undefined, questao_ref=undefined){
    const { auth_token, inscricao_numero_ref } = this.props.aluno;

    var qs = require('qs');

    const config = {
        "Content-Type": "application/x-www-form-urlencoded"
    }
    const requestBody = qs.stringify({
        auth_token,
        inscricao_numero_ref,
        acao,
        questao_ref
    }); 

    this.setState({processando: true, habilitaAnterior: false, questao: undefined}, ()=>{
      axios.post(process.env.REACT_APP_API_URL
        + 'buscaQuestao',
        requestBody,
        config)
        .then(res => {     

            let respostaRadio = undefined;
            let respostasCheckbox = [];
            let respostaDiscursiva = '';
            let discursivaObjetivas = [];      
            let habilitaProxima = false;          

            if (res.data.questao.tipo !== 2)
              Object.values(res.data.questao.alternativas).map(alternativa=>{
                discursivaObjetivas[alternativa.key] = undefined;
              });
            

            if (res.data.questao.respondida){
              habilitaProxima = true;
              respostasCheckbox = res.data.questao.resposta.checkbox;
              respostaRadio = (res.data.questao.resposta.radio != null) ? 
                res.data.questao.resposta.radio : undefined;
              respostaDiscursiva = res.data.questao.resposta.discursiva;
              if (res.data.questao.tipo !== 2)
                Object.values(res.data.questao.alternativas).map(alternativa=>{                
                  if (res.data.questao.resposta.discursiva_objetivas[alternativa.key] !== undefined)
                    discursivaObjetivas[alternativa.key] = res.data.questao.resposta.discursiva_objetivas[alternativa.key];
                  else
                    discursivaObjetivas[alternativa.key] = undefined;
                });
            } 
            
            this.setState({ 
              questao: res.data.questao,              
              percentualProgresso: res.data.percentualProgresso,
              habilitaProxima, 
              respostaRadio,
              respostasCheckbox,
              respostaDiscursiva,
              discursivaObjetivas });

        })
        .catch((error) => {       
          console.log(error); 
          message.error('Falha na conexão, verifique sua conexão com a Internet e tente recarregar a página.');                       
        })
        .finally(() => {
          this.setState({ processando: false, habilitaAnterior: true });
        });
    });  
  }

  onSelecionaRespostaRadio(e){
    this.setState({
      respostaRadio: e.target.value,
      habilitaProxima: true,
    });
  }

  onSelecionaRespostaCheckbox(respostasCheckbox) {
    const { questao } = this.state;
    let discursivaObjetivas = this.state.discursivaObjetivas;

    Object.values(questao.alternativas).map(alternativa=>{
      if (alternativa.desmarca_demais === 'S'){
        if (respostasCheckbox.indexOf(alternativa.key) >= 0){
          respostasCheckbox = [alternativa.key];
          discursivaObjetivas = [];
        } 
      }
    });
    this.setState({
      respostasCheckbox,
      discursivaObjetivas,
      habilitaProxima: (respostasCheckbox.length),
    });
  }

  onChangeDiscursiva(e){
    this.setState({
      habilitaProxima: (e.target.value.length),
      respostaDiscursiva: e.target.value,
    })
  }
  
  onChangeDiscursivaAlternativa(e, key){
    let discursivaObjetivas = this.state.discursivaObjetivas;
    discursivaObjetivas[key] = e.target.value;
    this.setState({
      discursivaObjetivas,
    })
  }
  
  questaoAnterior(){    
    const { questao } = this.state;
    if (questao.respondida)
      this.respondeQuestao('anterior');
    else
      this.buscaQuestao('anterior', questao.ref);
  }

  proximaQuestao(){
    this.respondeQuestao('próxima');
  }

  //validação e requisição para salvar resposta
  respondeQuestao(acao){

    let erro = '';

    const { questao, respostaRadio, respostaDiscursiva, 
      discursivaObjetivas, respostasCheckbox } = this.state;
    const { auth_token, inscricao_numero_ref } = this.props.aluno;

    switch (questao.tipo){
      case 0:
        //objetiva de escolha única
        if (questao.alternativas[respostaRadio].inclui_discursiva === 'S' 
          && discursivaObjetivas[respostaRadio] === undefined)
          erro = 'A alternativa que você marcou requer uma resposta, preencha na caixa que acompanha a alternativa.';
        else if (discursivaObjetivas[respostaRadio] !== undefined)
          if (questao.alternativas[respostaRadio].inclui_discursiva === 'S'
            && !discursivaObjetivas[respostaRadio].length)
            erro = 'Alguma das alternativas que você marcou requer uma resposta, preencha na caixa que acompanha a alternativa.'; 
        break;
      case 1:
        //objetiva de múltiplas escolhas
        respostasCheckbox.map(respostaCheckbox => {
          if (questao.alternativas[respostaCheckbox].inclui_discursiva === 'S' 
            && discursivaObjetivas[respostaCheckbox] === undefined)
            erro = 'Alguma das alternativas que você marcou requer uma resposta, preencha na caixa que acompanha a alternativa.'; 
          else if (discursivaObjetivas[respostaCheckbox] !== undefined)
            if (questao.alternativas[respostaCheckbox].inclui_discursiva === 'S' 
              && !discursivaObjetivas[respostaCheckbox].length)
              erro = 'Alguma das alternativas que você marcou requer uma resposta, preencha na caixa que acompanha a alternativa.'; 

        });
        break;
      default:
        //discursiva
        if (!respostaDiscursiva.length)
          erro = 'Resposta não pode estar em branco'; 

        break;
    }

    if (erro.length)
      this.errorMsg(erro);                       
    else {
      var qs = require('qs');

      const config = {
          "Content-Type": "application/x-www-form-urlencoded"
      }
      const requestBody = qs.stringify({
          auth_token,
          inscricao_numero_ref,
          questao_ref: questao.ref,
          respostaRadio,
          respostaDiscursiva,
          respostasCheckbox,
          discursivaObjetivas,
      });

      this.setState({processando: true, habilitaAnterior: false, habilitaProxima: false}, ()=>{
        axios.post(process.env.REACT_APP_API_URL
          + 'respondeQuestao',
          requestBody,
          config)
          .then(res => {      
              if (res.data.sucesso)
                this.buscaQuestao(acao, questao.ref);             
              else 
                this.errorMsg(res.data.msg);              
          })
          .catch((error) => {    
            this.setState({ habilitaProxima: true });
            message.error('Falha na conexão, verifique sua conexão com a Internet e tente recarregar a página.');                       
          })
          .finally(() => {
            this.setState({ processando: false, habilitaAnterior: true });
          });
      });
    }

  }

  errorMsg(msg) {
    //TODO: investigar e corrigir warning que aparece no console
    let secondsToGo = 10;
    const modal = Modal.warning({
      title: msg,
      content: `Esse alerta fecha automaticamente em ${secondsToGo} segundos.`,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content: `Esse alerta fecha automaticamente em ${secondsToGo} segundos.`,
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  }

  render() {
    const {processando, 
      questao, 
      respostaRadio, 
      respostasCheckbox,
      respostaDiscursiva,
      discursivaObjetivas, 
      habilitaProxima, 
      habilitaAnterior,
      percentualProgresso} = this.state; 

    const { aluno } = this.props;

    const { 
      onSelecionaRespostaRadio, 
      onSelecionaRespostaCheckbox, 
      onChangeDiscursiva,
      onChangeDiscursivaAlternativa, 
      questaoAnterior,
      proximaQuestao } = this;
    const windowWidth = this.props.windowWidth;

    const radioStyle = {
      display: 'block',
      lineHeight: '30px',
      marginLeft: '0px',
      whiteSpace: 'unset',
    };

    const inputStyle = {
      display: "block", 
      width: 100, 
      marginLeft: 0,
      marginBottom: 0,
    };

    let mostraAnterior = false;
    if (questao !== undefined)
      mostraAnterior = !(questao.bloco_ordem === 1 && questao.ordem === 1);

    return (

        <LayoutSite>
          
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Assistência Estudantil</Breadcrumb.Item>
              <Breadcrumb.Item>Inscrição atual</Breadcrumb.Item>
              <Breadcrumb.Item>3. Questionário</Breadcrumb.Item>

            </Breadcrumb>          

            <Layout className="site-layout-background" 
              style={{ 
                marginTop: "24px",
                padding: '12px 0' }}>            
                  <Content 
                    style={{ padding: '0 12px', minHeight: 280 }}>
                      <Space 
                        direction="vertical"
                        style={{width: "100%"}}>
                          <Button 
                            type="link" 
                            onClick={()=>this.props.onVoltarClick()}
                            style={{padding: "0"}}>Voltar</Button>
                          <Title level={4}>Questionário socioeconômico</Title>
                                    
                          <div>
                            <Space 
                              direction="vertical"
                              style={{width: "100%"}}>
                              {(percentualProgresso === 100) && (
                                <Alert
                                  message="Etapa concluída!"
                                  description={
                                    <p>
                                      Lembre que você deve anexar os arquivos da documentação na 
                                      <Button 
                                        type="link" 
                                        onClick={()=>this.props.onProximaEtapa()}
                                        style={{padding: 0, marginLeft: 2}}>próxima etapa</Button>
                                      , para finalizar a inscrição. Você respondeu todas as questões e poderá revisá-las até finalizar a inscrição.
                                    </p>
                                  }
                                  type="success"
                                  showIcon
                                />
                              )}
                              
                              <Text>Responda a todas as questões para finalizar essa etapa da inscrição. Algumas alternativas poderão exigir documentação comprobatória quando marcadas. </Text>
                              <Space 
                                  direction="vertical"
                                  style={{width: "100%"}}>
                                  <Text>Progresso</Text>
                                  <Progress percent={percentualProgresso} />
                              {(questao === undefined) ? (
                                <div>
                                  <Text>Carregando ...</Text>
                                  <Skeleton active />                             
                                </div>
                              ): (
                                <div>
                                  {processando ? (
                                    <div>
                                      <Text>Carregando ...</Text>
                                      <Skeleton active />                             
                                    </div>
                                  ):(
                                    <Space 
                                      direction="vertical"
                                      style={{width: "100%"}}>
                                      <Text strong>Bloco</Text>
                                      <Text>{questao.bloco_ordem}. {questao.bloco_titulo}</Text>
                                      <Text strong>Questão</Text>
                                      {!questao.condicional ? (
                                        <Text>{questao.ordem}. {questao.enunciado}</Text>
                                      ):(
                                        <Text>{questao.enunciado}</Text>
                                      )}
                                      {questao.tipo === 0 && (
                                        <Space 
                                        direction="vertical"
                                        style={{width: "100%"}}>
                                          <Radio.Group 
                                            onChange={onSelecionaRespostaRadio} 
                                            value={respostaRadio}>
                                            {Object.values(questao.alternativas).map(alternativa=>
                                                
                                              <div key={alternativa.key}>
                                                <Radio 
                                                  style={radioStyle} 
                                                  value={alternativa.key}>
                                                  {alternativa.descritivo}                                                
                                                </Radio>
                                                {(alternativa.key === respostaRadio
                                                    && alternativa.inclui_discursiva === 'S') ? 
                                                    <Input 
                                                      value={discursivaObjetivas[alternativa.key]}
                                                      style={inputStyle}
                                                      onChange={(e) => onChangeDiscursivaAlternativa(e, alternativa.key)} /> : null}
                                              </div>
                                              
                                            )}
                                          </Radio.Group>
                                          
                                        </Space>
                                      )}
                                      {questao.tipo === 1 && (
                                        <Space 
                                          direction="vertical"
                                          style={{width: "100%"}}>
                                          <Checkbox.Group 
                                            value={respostasCheckbox}
                                            onChange={onSelecionaRespostaCheckbox}>      
                                            {Object.values(questao.alternativas).map(alternativa=>
                                              <div key={alternativa.key}>
                                              <Checkbox                                                 
                                                style={radioStyle} 
                                                value={alternativa.key}>
                                                {alternativa.descritivo}                                                                                               
                                              </Checkbox>
                                              {(respostasCheckbox.indexOf(alternativa.key) >= 0 
                                                  && alternativa.inclui_discursiva === 'S') ? 
                                                  <Input 
                                                    value={discursivaObjetivas[alternativa.key]}
                                                    onChange={(e) => onChangeDiscursivaAlternativa(e, alternativa.key)}
                                                    style={inputStyle} /> : null} 
                                              </div>
                                            )}       
                                          </Checkbox.Group>                             
                                        </Space>
                                      )}
                                      {questao.tipo === 2 && (
                                        <Space 
                                          direction="vertical"
                                          style={{width: "100%"}}>
                                          <TextArea                                             
                                            value={respostaDiscursiva}
                                            onChange={onChangeDiscursiva}
                                            rows={4} 
                                            style={{resize: 'none'}}/>                            
                                        </Space>
                                      )}
                                    </Space>
                                  )}                                  

                                  </div>
                              )}                              
                                  <Space
                                    style={{marginTop: "10px"}}>
                                    {mostraAnterior && (
                                      <Button 
                                        type="primary" 
                                        disabled={!habilitaAnterior}
                                        icon={<LeftOutlined />}
                                        onClick={questaoAnterior}>Anterior</Button>)}
                                    <Button 
                                      type="primary" 
                                      disabled={!habilitaProxima}
                                      onClick={proximaQuestao}>Seguinte <RightOutlined /></Button>
                                  </Space>
                                </Space>

                            </Space>
                            
                          </div>
                                                   
                        <div
                          style={{width: "100%", minHeight: "30px"}}>

                        </div>
                      </Space>
                      
                  </Content>
            </Layout>
          
        </LayoutSite>        
    );
  }
}

export default windowSize(Questionario);