import React from 'react';
import { UserOutlined, EyeTwoTone, EyeInvisibleOutlined} from '@ant-design/icons';
import { 
    Modal, 
    Space, 
    Button, 
    Input, 
    Alert, 
    message,
    Typography } from 'antd';
import InputMask from 'react-input-mask';

import axios from 'axios';

const { Text } = Typography;

class BotaoLogin extends React.Component {

    state = {
        showLogin: false,
        processando: false,
        username: "",
        password: "",
        messageError: "",
    }

    constructor(props){        
        super(props);     

        this.onEntrarClick = this.onEntrarClick.bind(this);
        this.onCancelarClick = this.onCancelarClick.bind(this);
        this.onOKClick = this.onOKClick.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    handleLogin() {
        const { username, password } = this.state;
        const { onSucessoLogin } = this.props;

        if (username.length && password.length) {        
            
            var qs = require('qs');

            const config = {
                "Content-Type": "application/x-www-form-urlencoded"
            }
            const requestBody = qs.stringify({
                username, password
            }); 

            axios.post(process.env.REACT_APP_API_URL
                + 'autentica',
                requestBody,
                config)
                .then(res => {
                

                    if (res.data.sucesso){
                        //autenticação realizada com sucesso
                        message.success('Autenticação realizada com sucesso!');                        
                        //1. fecha modal e reseta estados do componente
                        this.setState({
                            showLogin: false,
                            messageError: "",
                            username: "",
                            password: ""}, ()=>{
                            //2. retorna o aluno logado para o componente pai
                            onSucessoLogin(res.data.aluno);
                        });
                        

                    } else {
                        //autenticação realizada com sucesso
                        this.setState({
                            messageError: res.data.message,
                        });
                    }

                })
                .catch((error) => {
                    this.setState({
                        messageError: 'Verifique sua conexão com a Internet.',
                    });
                })
                .finally(() => {
                    if (this._mounted)
                        this.setState({ processando: false });
                });
            

        } else {
            this.setState({
                processando: false,
                messageError: "O CPF e senha devem estar preenchidos.",
            });
        }

    }

    onEntrarClick(){
        this.setState({showLogin: true});
    }

    onCancelarClick(){
        if (!this.state.processando)
            this.setState({
                showLogin: false,
                messageError: "",
                username: "",
                password: ""});
    }

    onOKClick(){
        this.setState({ messageError: "", processando: true }, 
            ()=>this.handleLogin());
    }

    render(){
        const { username, password, showLogin, processando, messageError } = this.state;
        const { onEntrarClick, onCancelarClick, onOKClick } = this;
        const { windowWidth } = this.props;
        const botaoWidth = (windowWidth < 640) ? "90px" : "120px";


        return (
            <div>      
                     
                <div
                    ref={this.props.botaoLoginRef}
                    className="botao-cabecalho-login botao-cabecalho-login-hover"            
                    style={{
                        width: botaoWidth,
                        // height: "70px",
                        height: "48px" }}                        
                    onClick={() => !showLogin ? onEntrarClick() : null}>
                    <UserOutlined style={{color: "#9cff81"}}/> 
                    <a style={{
                        color: "#9cff81",
                        fontSize: "14px",
                        letterSpacing: "0.15em"}}> Aluno</a>
                </div>

                {showLogin  && 
                (<Modal
                    title="Acesso do Aluno"
                    visible={true}
                    style={{ top: 20 }}
                    width="350px"
                    onCancel={onCancelarClick}
                    footer={null}>
                    <Space 
                        direction="vertical"
                        style={{width: "100%"}}>
                        <Text>Matrícula:</Text>                       
                        
                        <Input
                            name="username" 
                            className="ant-input"
                            value={username} 
                            placeholder="A mesma utilizada no SUAP"
                            onChange={e=>this.setState({username: e.target.value})}
                            style={{
                                height: "48px"
                            }}/>
                        <Text>Senha:</Text>
                        <Input.Password
                            name="password"
                            placeholder="A mesma utilizada no SUAP"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            value={password}
                            onChange={e=>this.setState({password: e.target.value})}
                            style={{
                                height: "48px"
                            }}/>

                        {(messageError.length > 0) &&
                            <Alert message={messageError} type="error" />
                        }
 
                        <Button 
                            key="submit" 
                            type="primary" 
                            loading={processando} 
                            onClick={onOKClick}
                            style={{
                                width: "100%",
                                height: "60px",
                                marginTop: "10px",
                                fontSize: "16px",
                                fontWeight: "bold",
                                background: "#50ae60",
                                borderColor: "#50ae60"}}>
                            <Text style={{color: "white"}}>
                                {processando ? "Aguarde ..." : "Entrar"}
                                </Text>
                        </Button>
                    </Space>
                </Modal>)}
            </div>
        );
    }
}

export default BotaoLogin;