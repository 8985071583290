import React from 'react';

import { 
  Layout, 
  Space, 
  Breadcrumb, 
  Typography,
  Skeleton,
  Button,
  Alert,
  Popconfirm,
  message} from 'antd';

import axios from 'axios';

import windowSize from 'react-window-size';
import LayoutSite from '../../componentes/LayoutSite';
import EdicaoComponenteFamiliar from './EdicaoComponenteFamiliar';
import VisualizacaoComponenteFamiliar from './VisualizacaoComponenteFamiliar';


const { Content } = Layout;
const { Title, Text } = Typography;


class ComponentesFamilia extends React.Component {  

  constructor(props){
    super(props);

    this.state = {
      processando: false,    
      novo_familiar: false,
      edita_familiar: false,
      visualiza_familiar: false,
      messageError: [],
      familiares: [],
      familiarSelecionado: undefined,
    }

    this.onEdicaoCancelaClick = this.onEdicaoCancelaClick.bind(this);
    this.onAdicionaClick = this.onAdicionaClick.bind(this);
    this.onEdicaoSucesso = this.onEdicaoSucesso.bind(this);
    this.onEditaComponenteClick = this.onEditaComponenteClick.bind(this);
    this.carregaComponentesFamilia = this.carregaComponentesFamilia.bind(this);
    this.excluiComponenteConfirma = this.excluiComponenteConfirma.bind(this);
    this.onVisualizaComponenteClick = this.onVisualizaComponenteClick.bind(this);

  }

  onAdicionaClick(){
    if (this._mounted)
        this.setState({novo_familiar: true});
  }

  onEditaComponenteClick(familiarRef){
      const { familiares } = this.state;
      let familiarSelecionado = familiares[familiarRef];
      this.setState({
          edita_familiar: true,
          familiarSelecionado,
      });
  }

  onEdicaoCancelaClick(){
    if (this._mounted)
      this.setState({
          visualiza_familiar: false,
          novo_familiar: false, 
          edita_familiar: false,
          familiarSelecionado: undefined,
        });
  }

  onVisualizaComponenteClick(familiarRef){
    const { familiares } = this.state;
    let familiarSelecionado = familiares[familiarRef];
    this.setState({
        visualiza_familiar: true,
        familiarSelecionado,
    });
  }

  onEdicaoSucesso(){
      const novo = (this.state.familiarSelecionado === undefined); 
      this.setState({novo_familiar: false, edita_familiar: false, familiarSelecionado: undefined}, ()=>{
        if (novo)
            message.success('Componente adicionado com sucesso!'); 
        else
            message.success('Alteração realizada com sucesso!'); 
        
        this.carregaComponentesFamilia();
      });
  }

  excluiComponenteConfirma(familiarRef){
    const { auth_token } = this.props.aluno;
    const { familiares } = this.state;;

    var qs = require('qs');

    const config = {
        "Content-Type": "application/x-www-form-urlencoded"
    }
    const requestBody = qs.stringify({
        auth_token, familiarRef
    }); 

    this.setState({processando: true}, ()=>{
      axios.post(process.env.REACT_APP_API_URL
        + 'excluiComponenteFamilia',
        requestBody,
        config)
        .then(res => {           
            if (res.data.sucesso){    
                delete familiares[familiarRef];
                message.success(res.data.message); 
            } 
        })
        .catch((error) => {              
          message.error('Falha na conexão, verifique sua conexão com a Internet e tente recarregar a página.');                       
        })
        .finally(() => {
          this.setState({ processando: false });
        });
    });   
  }

  carregaComponentesFamilia(){
    const { auth_token } = this.props.aluno;

    var qs = require('qs');

    const config = {
        "Content-Type": "application/x-www-form-urlencoded"
    }
    const requestBody = qs.stringify({
        auth_token
    }); 

    this.setState({processando: true, familiares: []}, ()=>{
      axios.post(process.env.REACT_APP_API_URL
        + 'listaComponentesFamilia',
        requestBody,
        config)
        .then(res => {           
            if (res.data.sucesso){     
                           
                let familiares = []; 
                res.data.componentes.map(componente=>{
                    familiares[componente.key] = componente;
                });
                
                this.setState({
                    familiares,
                });       
            } 
        })
        .catch((error) => {              
          message.error('Falha na conexão, verifique sua conexão com a Internet e tente recarregar a página.');                       
        })
        .finally(() => {
          this.setState({ processando: false });
        });
    });    
  }

  componentWillUnmount(){
    this._mounted = false;
  }
  
  componentDidMount(){      
    this._mounted = true;
    window.scrollTo(0, 0);   
    this.carregaComponentesFamilia();
  } 

  render() {
    const { processando, familiares, novo_familiar, edita_familiar, 
        visualiza_familiar, familiarSelecionado } = this.state; 

    const { onEdicaoCancelaClick, onAdicionaClick, onEdicaoSucesso,
        onEditaComponenteClick, excluiComponenteConfirma,
        onVisualizaComponenteClick } = this;

    const { auth_token } = this.props.aluno;

    const windowWidth = this.props.windowWidth;

    if (visualiza_familiar)
        return (<VisualizacaoComponenteFamiliar 
                    familiarSelecionado={familiarSelecionado}
                    onCancelaClick={onEdicaoCancelaClick} />);
    else if (novo_familiar)
        return (<EdicaoComponenteFamiliar 
                    auth_token={auth_token}
                    onEdicaoSucesso={onEdicaoSucesso}
                    onCancelaClick={onEdicaoCancelaClick} />);
    else if (edita_familiar)
        return (<EdicaoComponenteFamiliar 
                    auth_token={auth_token}
                    familiarSelecionado={familiarSelecionado}
                    onEdicaoSucesso={onEdicaoSucesso}
                    onCancelaClick={onEdicaoCancelaClick} />);
    else
        return (

            <LayoutSite>
            
                <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Assistência Estudantil</Breadcrumb.Item>
                <Breadcrumb.Item>Inscrição atual</Breadcrumb.Item>
                <Breadcrumb.Item>2. Componentes da família</Breadcrumb.Item>

                </Breadcrumb>          

                <Layout className="site-layout-background" 
                style={{ 
                    marginTop: "24px",
                    padding: '12px 0' }}>            
                    <Content 
                        style={{ padding: '0 12px', minHeight: 280 }}>
                        <Space 
                            direction="vertical"
                            style={{width: "100%"}}>
                            <Button 
                                type="link" 
                                onClick={()=>this.props.onVoltarClick()}
                                style={{padding: "0"}}>Voltar</Button>
                            <Title level={4}>Componentes da família</Title>
                                        
                            <div>
                                <Space 
                                direction="vertical"
                                style={{width: "100%"}}>
                                <Text>Informação sobre quem são os membros da sua família, renda e o grau de parentesco.</Text>  
                                <Alert message="Observação: Omitir o cadastro dos membros da sua família, bem como as respectivas informações de renda, implicam na sua desclassificação." type="error" />
                                
                                <Button
                                    type="primary"
                                    onClick={onAdicionaClick}>Adicionar</Button>
                            {processando ? (
                                <div>
                                <Text>Carregando ...</Text>
                                <Skeleton active />                             
                                </div>
                            ) : (
                                <div>
                                {(windowWidth < 640) ? (
                                    <table style={{width: "100%"}}>
                                        <thead className="ant-table-thead">
                                            <tr>
                                                <th className="ant-table-cell">Nome</th> 
                                            </tr>
                                        </thead>
                                        <tbody className="ant-table-tbody">
                                            {Object.values(familiares).map(rowData =>
                                            <tr 
                                                key={rowData.key}
                                                className="ant-table-row ant-table-row-level-0">
                                                <td className="ant-table-cell">
                                                    <Space
                                                        direction="vertical"
                                                        width="100%">
                                                        <Text>{rowData.nome}</Text>
                                                        <Space
                                                            direction="horizontal"
                                                            width="100%">                                            
                                                            <Text strong>Parentesco:</Text>
                                                            <Text>{rowData.parentesco_descricao}</Text>                                            
                                                            <Text strong>Idade:</Text>
                                                            <Text>{rowData.idade}</Text>                                                              
                                                        </Space>
                                                        <Space
                                                            direction="horizontal"
                                                            width="100%">  
                                                            <Button type="default"
                                                                onClick={()=>onVisualizaComponenteClick(rowData.key)}
                                                                style={{padding: "0"}}>Visualizar</Button>
                                                            <Button type="default"
                                                                onClick={()=>onEditaComponenteClick(rowData.key)}
                                                                style={{padding: "0"}}>Editar</Button>                                                            
                                                            <Popconfirm 
                                                                placement="top" 
                                                                title="Confirma a exclusão deste componente familiar?" 
                                                                onConfirm={()=>excluiComponenteConfirma(rowData.key)} 
                                                                okText="Sim" cancelText="Não">
                                                                <Button type="default"
                                                                    style={{padding: "0"}}>
                                                                    Excluir
                                                                </Button>
                                                            </Popconfirm>
                                                        </Space>
                                                    </Space>
                                                </td>
                                            </tr>
                                            )}
                                            {(!Object.values(familiares).length) && (
                                                <tr>
                                                    <td>Nenhuma pessoa adicionada. Clique em adicionar para começar.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                ):(
                                    <table style={{width: "100%"}}>
                                        <thead className="ant-table-thead">
                                            <tr>
                                                <th className="ant-table-cell">Nome</th>                            
                                                <th className="ant-table-cell">Parentesco</th>                            
                                                <th className="ant-table-cell">Idade</th>                            
                                                <th className="ant-table-cell">Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody className="ant-table-tbody">
                                            {Object.values(familiares).map(rowData => ( 
                                            <tr 
                                                key={rowData.key}
                                                className="ant-table-row ant-table-row-level-0">
                                                <td className="ant-table-cell">{rowData.nome}</td>
                                                <td className="ant-table-cell">{rowData.parentesco_descricao}</td>
                                                <td className="ant-table-cell">{rowData.idade}</td>
                                                <td className="ant-table-cell">
                                                    <Button type="default"
                                                        onClick={()=>onVisualizaComponenteClick(rowData.key)}
                                                        style={{padding: "0"}}>Visualizar</Button>
                                                    <Button type="default"
                                                        onClick={()=>onEditaComponenteClick(rowData.key)}
                                                        style={{padding: "0"}}>Editar</Button>
                                                    <Popconfirm 
                                                        placement="topRight" 
                                                        title="Confirma a exclusão deste componente familiar?" 
                                                        onConfirm={()=>excluiComponenteConfirma(rowData.key)} 
                                                        okText="Sim" cancelText="Não">
                                                        <Button type="default"
                                                            style={{padding: "0"}}>
                                                            Excluir
                                                        </Button>
                                                    </Popconfirm>
                                                </td>
                                            </tr>)
                                            )}
                                            {(!Object.values(familiares).length) && (
                                                <tr>
                                                    <td colSpan={4}>Nenhuma pessoa adicionada. Clique em adicionar para começar.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    )}
                                    </div>
                                )}
                                <Button
                                    onClick={()=>this.props.onVoltarClick()}>Voltar</Button>                                
                                    
                                </Space>
                                
                            </div>
                                                    
                            <div
                            style={{width: "100%", minHeight: "30px"}}>

                            </div>
                        </Space>
                        
                    </Content>
                </Layout>
            
            </LayoutSite>        
        );
  }
}

export default windowSize(ComponentesFamilia);