import React from 'react';

import { Layout } from 'antd';
import '../../node_modules/antd/dist/antd.css';
import '../assistencia.css';

import windowSize from 'react-window-size';
import BotaoLogin from './BotaoLogin';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MenuAluno from './MenuAluno';

const { Header, Content, Footer } = Layout;

class LayoutSite extends React.Component {

  constructor(props){
    super(props);

    this.sucessoLogin = this.sucessoLogin.bind(this);
    this.logoff = this.logoff.bind(this);
  }

  sucessoLogin(aluno){
    const { dispatch } = this.props;
    let action = {};
    action.type = 'AUTENTICA';
    action.aluno = aluno;
    dispatch(action);
  }

  logoff(){
    const { dispatch } = this.props;
    let action = {};
    action.type = 'LOGOFF';
    dispatch(action);
    this.props.history.push('/');
  }

  render() {

    const {windowWidth, aluno} = this.props;
    const paddingLR = (windowWidth < 640) ? "20px" : "50px";

    return (
        <Layout>
          <Header className="fundo-verde"
              style={{
              lineHeight: 0,
              position: "fixed",
              width: "100%",
              zIndex: 10,
              height: "48px",
              paddingLeft: paddingLR,
              paddingRight: paddingLR}}>
            <div
              style={{
                  display: "inline-flex",
                  width: "100%",
                  height: "100%"
              }}>
              <div className="fundo-verde"
                  style={{width: "60%"}}>
                  <div
                      style={{
                          width: "100%",
                          height: "44px",
                          paddingTop: "6px"
                          }}>
                      <img src="images/logo_if.png" style={{ width: "130px"}}/>
                  </div>

              </div>

              <div
                className="fundo-verde"
                style={{
                    width: "40%",
                    height: "100%"
                }}>

                {aluno.auth_token.length ? (
                  <MenuAluno
                    aluno={aluno}
                    windowWidth={windowWidth}
                    onSairClick={this.logoff} />
                ) : (
                  <BotaoLogin
                    botaoLoginRef={this.props.botaoLoginRef}
                    onSucessoLogin={this.sucessoLogin}
                    windowWidth={windowWidth}/>
                )}

              </div>


            </div>
          </Header>
          <Content
            style={{
              position: "absolute",
              top: "48px",
              width: "100%",
              paddingLeft: paddingLR,
              paddingRight: paddingLR }}>

            {this.props.children}

          </Content>

          <Footer
            style={{
              position: "fixed",
              width: "100%",
              height: "40px",
              bottom: "0px",
              paddingTop: "10px",
              textAlign: 'center' }}>Desenvolvido pela CSI.</Footer>
        </Layout>
    );
  }
}

export default withRouter(
  connect(
  state=>({aluno: state.aluno})
  )(windowSize(LayoutSite)));