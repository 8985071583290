import React from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Typography } from 'antd';

const { Text } = Typography;

class MenuAluno extends React.Component {

    state = {
        showMenu: false,
    }

    constructor(props){
        super(props);

        this.onPageScroll = this.onPageScroll.bind(this);
    }

    onPageScroll(){
        //document.getElementsByClassName('ant-dropdown')[0].classList.add('ant-dropdown-hidden');
        //console.log(this.alunoDropdown);
    }

    componentDidMount(){
        //window.addEventListener('scroll', this.onPageScroll);
    }

    render(){
        const { aluno } = this.props;
        const { windowWidth } = this.props;
        const botaoWidth = (windowWidth < 640) ? "140px" : "140px";

        let primeiroNome = aluno.nome;
        if (primeiroNome.length){
            primeiroNome = primeiroNome.split(' ')[0];
            if (primeiroNome.length > 13)
                primeiroNome = primeiroNome.slice(0,10) + '...';
        }

        const menuLateral = (
            <Menu>
                <Menu.Item key="0">
                <a href="./">Editais</a>
                </Menu.Item>
                <Menu.Item key="1">
                <a href="./inscricoes">Minhas inscrições</a>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="2" onClick={this.props.onSairClick}>Sair</Menu.Item>
            </Menu>
        );

        return (
            <div>

                <div
                    className="botao-cabecalho-usuario"
                    style={{
                        width: botaoWidth,
                        height: "48px"}}>

                    <Dropdown
                        className="botao-cabecalho-usuario botao-cabecalho-usuario-hover"
                        overlay={menuLateral}
                        placement="bottomRight"
                        trigger={['click','hover']}>
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                paddingTop: "14px"}}>
                            <Text style={{
                                color: "white",
                                fontSize: "13px",
                                top: "-4px",
                                height: "100%",
                                position: "relative"}}>{primeiroNome}</Text>
                            <MenuOutlined style={{
                                    marginLeft: "10px",
                                    fontSize: "22px",
                                    color: "white",
                                    backgroundColor: "" }}/>
                        </div>
                    </Dropdown>
                </div>
            </div>
        );
    }
}

export default MenuAluno;