import React from 'react';
import ReactDOM from 'react-dom';
import Inicio from './modulos/Inicio';
import NovaInscricao from './modulos/NovaInscricao';
import NovoRecurso from './modulos/NovoRecurso';

import { Route, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import InscricaoAtual from './modulos/InscricaoAtual';
import ListagemInscricao from './modulos/ListagemInscricao';
// import { registerServiceWorker } from './serviceWorker';

var history = require('history').createBrowserHistory({forceRefresh: true});

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Route exact path={'/'} component={Inicio} />
            <Route exact path={'/inscricoes'} component={ListagemInscricao} />
            <Route exact path={'/novaInscricao'} component={NovaInscricao} />
            <Route exact path={'/inscricao'} component={InscricaoAtual} />
            <Route exact path={'/novoRecurso'} component={NovoRecurso} />
        </Router>
    </Provider>,
	document.getElementById('container'));

// registerServiceWorker();

