import React from 'react';

import { 
  Layout, 
  Space, 
  Breadcrumb, 
  Typography,
  Button} from 'antd';

import windowSize from 'react-window-size';
import LayoutSite from '../../componentes/LayoutSite';


const { Content } = Layout;
const { Title, Text } = Typography;


class VisualizacaoComponenteFamiliar extends React.Component {  

  constructor(props){
    super(props);    
  }

  componentWillUnmount(){
    this._mounted = false;
  }
  
  componentDidMount(){      
    this._mounted = true;
    window.scrollTo(0, 0);   
  } 

  render() {
    const { familiarSelecionado } = this.props;     
    
    const windowWidth = this.props.windowWidth;

    return (

        <LayoutSite>
          
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Assistência Estudantil</Breadcrumb.Item>
              <Breadcrumb.Item>Inscrição atual</Breadcrumb.Item>
              <Breadcrumb.Item>2. Componentes da família</Breadcrumb.Item>

            </Breadcrumb>          

            <Layout className="site-layout-background" 
              style={{ 
                marginTop: "24px",
                padding: '12px 0' }}>            
                  <Content 
                    style={{ padding: '0 12px', minHeight: 280 }}>
                      <Space 
                        direction="vertical"
                        style={{width: "100%"}}>
                          <Button 
                            type="link" 
                            onClick={()=>this.props.onCancelaClick()}
                            style={{padding: "0"}}>Voltar</Button>
                          <Title level={4}>Visualização do componente</Title>
                                    
                          <div>
                            <Space 
                              direction="vertical"
                              style={{width: "100%"}}>
                            
                              <Text style={{display: "block"}}>Nome:</Text>
                              <Text strong>{familiarSelecionado.nome}</Text>                              

                              <Text>Sexo</Text>                             
                              <Text strong>{(familiarSelecionado.sexo === 'M') ? 'Masculino' : 'Feminino'}</Text>                              
                              
                              <Text style={{display: "block"}}>Data de nascimento:</Text>
                              <Text strong>{familiarSelecionado.data_nascimento}</Text> 

                              <Text style={{display: "block"}}>Parentesco:</Text>
                              <Text strong>{familiarSelecionado.parentesco_descricao}</Text> 

                              <Text style={{display: "block"}}>Estuda:</Text>
                              <Text strong>{(familiarSelecionado.estuda === 'S') ? 'Sim' : 'Não'}</Text>                              

                              <Text style={{display: "block"}}>Alfabetizado:</Text>
                              <Text strong>{(familiarSelecionado.alfabetizado === 'S') ? 'Sim' : 'Não'}</Text>                              
                            
                              <Text style={{display: "block"}}>Última série:</Text>
                              <Text strong>{familiarSelecionado.estudo_ano}</Text> 
                              
                              <Text style={{display: "block"}}>Trabalha:</Text>
                              <Text strong>{(familiarSelecionado.trabalha === 'S') ? 'Sim' : 'Não'}</Text>                              
                              
                              {(familiarSelecionado.trabalha === "S") && (
                                <Space
                                  direction="vertical">
                                    <div>
                                      <Text style={{display: "block"}}>Descrição do trabalho:</Text>
                                      <Text strong>{familiarSelecionado.trabalho_descricao}</Text> 
                                    </div>
                                    <div>
                                      <Text style={{display: "block"}}>* Renda mensal:</Text>
                                      <Text strong>{familiarSelecionado.trabalho_remuneracao}</Text>                                   
                                    </div>
                                </Space>
                              )}

                              <Button
                                onClick={()=>{this.props.onCancelaClick()}}>Voltar</Button>

                            </Space>   
                            
                          </div>
                                                   
                        <div
                          style={{width: "100%", minHeight: "30px"}}>

                        </div>
                      </Space>
                      
                  </Content>
            </Layout>
          
        </LayoutSite>        
    );
  }
}

export default windowSize(VisualizacaoComponenteFamiliar);