import React from 'react';

import {
  Layout,
  Space,
  Breadcrumb,
  Typography,
  Radio,
  Input,
  Button} from 'antd';
import InputMask from 'react-input-mask';

import axios from 'axios';

import windowSize from 'react-window-size';
import LayoutSite from '../../componentes/LayoutSite';


const { Content } = Layout;
const { Title, Text } = Typography;


class DadosPessoais extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      processando: false,
      messageError: [],
      endereco_localizacao: undefined,
      curso_nome: "",
      curso_ano: "",
      conta_tipo: undefined,
      conta_banco: undefined,
      conta_agencia: undefined,
      conta_numero: undefined,
      contato_celular: "",
      trabalha: undefined,
      trabalho_descricao: "",
      trabalho_remuneracao: "",
    }

    this.onLocalizacaoChange = this.onLocalizacaoChange.bind(this);
    this.onContaTipo = this.onContaTipo.bind(this);
    this.salvarAlteracoes = this.salvarAlteracoes.bind(this);
    this.onCursoAnoChange = this.onCursoAnoChange.bind(this);
    this.onTrabalhaRadioChange = this.onTrabalhaRadioChange.bind(this);
    this.onTrabalhoRendaChange = this.onTrabalhoRendaChange.bind(this);

  }

  componentWillUnmount(){
    this._mounted = false;
  }

  componentDidMount(){
    this._mounted = true;
    const { aluno } = this.props;

    window.scrollTo(0, 0);

    this.setState({
        endereco_localizacao: (aluno.endereco.localizacao !== undefined
            && aluno.endereco.localizacao !== null) ?
              aluno.endereco.localizacao : undefined,
          curso_ano: (aluno.curso.ano !== undefined
            && aluno.curso.ano !== null) ?
            aluno.curso.ano : "",
          trabalha: (aluno.trabalha !== undefined
            && aluno.trabalha !== null) ?
            aluno.trabalha : undefined,
          trabalho_descricao: (aluno.trabalho_descricao !== undefined
            && aluno.trabalho_descricao !== null) ?
            aluno.trabalho_descricao : "",
          trabalho_remuneracao: (aluno.trabalho_remuneracao !== undefined
            && aluno.trabalho_remuneracao !== null) ?
            aluno.trabalho_remuneracao : "",
          conta_tipo: (aluno.conta.tipo !== undefined
            && aluno.conta.tipo !== null) ?
            aluno.conta.tipo : undefined,
          conta_banco: (aluno.conta.banco !== undefined
            && aluno.conta.banco !== null) ?
            aluno.conta.banco : "",
          conta_agencia: (aluno.conta.agencia !== undefined
            && aluno.conta.agencia !== null) ?
            aluno.conta.agencia : "",
          conta_numero: (aluno.conta.numero !== undefined
            && aluno.conta.numero !== null) ?
            aluno.conta.numero : "",
          contato_celular: (aluno.celular !== undefined
            && aluno.celular !== null) ?
            aluno.celular : ""
    });

  }

  onLocalizacaoChange(e){
    this.setState({
      endereco_localizacao: e.target.value,
    })
  }

  onCursoAnoChange(e){
    const { value } = e.target;
    const reg = /^\d{1,4}$/i;
    if (reg.test(value) || value === '') {
      this.setState({
        curso_ano: value,
      })
    }
  }

  onContaTipo(e){
    let conta_banco = this.state.conta_banco;
    let conta_agencia = this.state.conta_agencia;
    let conta_numero = this.state.conta_numero;

    if (e.target.value === -1){
      conta_banco = undefined;
      conta_agencia = undefined;
      conta_numero = undefined;
    }
    this.setState({
      conta_tipo: e.target.value,
      conta_banco,
      conta_agencia,
      conta_numero,
    })
  }

  onTrabalhaRadioChange(e){
    const { value } = e.target;

    if (value === 'S')
      this.setState({
        trabalha: value,
      });
    else
      this.setState({
        trabalha: value,
        trabalho_descricao: "",
        trabalho_remuneracao: "",
      });
  }

  onTrabalhoRendaChange(e){
    const { value } = e.target;
    const reg = /^\d{1,5}(\,\d{0,2})?$/i;
    if (reg.test(value) || value === '') {
      this.setState({
        trabalho_remuneracao: value,
      })
    }
  }

  salvarAlteracoes(){
    const {endereco_localizacao, curso_ano,
      conta_tipo, conta_banco, conta_agencia, conta_numero,
      contato_celular, trabalha, trabalho_descricao,
      trabalho_remuneracao } = this.state;

    const { auth_token } = this.props.aluno;

    let aluno = this.props.aluno;

    let messageError = [];

    if (endereco_localizacao === undefined)
      messageError.push('A localização do endereço não foi selecionada.');
    if (!curso_ano.length)
      messageError.push('O ano do curso não foi preenchido.');
    else if (isNaN(curso_ano))
      messageError.push('O ano do curso deve ser um número inteiro.');
    if (conta_tipo === undefined)
      messageError.push('O tipo da conta não foi selecionado.');

    if (trabalha === undefined)
      messageError.push('Informe se recebe alguma renda.');
    else if (trabalha === 'S'){
      if (!trabalho_descricao.length)
        messageError.push('A descrição da(s) renda(s) não foi preenchida.');
      if (!trabalho_remuneracao.length)
        messageError.push('O valor da(s) renda(s) não foi preenchido.');
    }

    if (contato_celular.replace(/_|\(|\)|\-| */g,'').length < 11)
      messageError.push('O celular não foi preenchido.');

    if (messageError.length)
      this.setState({messageError});
    else
      this.setState({messageError, processando: true}, ()=>{
        //envia requisição para o webservice
        let qs = require('qs');

        const config = {
          "Content-Type": "application/x-www-form-urlencoded"
        }

        const requestBody = qs.stringify(
          { auth_token, endereco_localizacao, curso_ano,
            conta_tipo, conta_banco, conta_agencia, conta_numero,
            contato_celular, trabalha, trabalho_descricao,
            trabalho_remuneracao }
        );

        axios.post(process.env.REACT_APP_API_URL
          + 'atualizaDadosPessoais',
          requestBody,
          config)
          .then(res => {
          if (res.data.sucesso){

            aluno.endereco.localizacao = endereco_localizacao;
            aluno.curso.ano = curso_ano;
            aluno.conta.tipo = conta_tipo;
            aluno.conta.banco = conta_banco;
            aluno.conta.agencia = conta_agencia;
            aluno.conta.numero = conta_numero;
            aluno.celular = contato_celular;
            aluno.trabalha = trabalha;
            aluno.trabalho_descricao = trabalho_descricao;
            aluno.trabalho_remuneracao = trabalho_remuneracao;

            this.props.onAtualizaDados(aluno);

          } else {

            res.data.message.map(message=>messageError.push(message));

          }
          })
          .catch((error) => {
            messageError.push("Verifique sua Internet e tente novamente.")
            this.setState({
              processando: false,
              messageError,
            });
          })
          .finally(()=>{
            if (this._mounted)
              this.setState({
                processando: false,
              messageError});
          });
      });

  }

  render() {
    const {processando, messageError, endereco_localizacao, curso_nome, curso_ano,
        conta_tipo, conta_banco, conta_agencia, conta_numero,
        contato_celular, trabalha, trabalho_descricao,
        trabalho_remuneracao } = this.state;

    const { aluno } = this.props;

    const windowWidth = this.props.windowWidth;

    const { onLocalizacaoChange, onContaTipo, salvarAlteracoes,
      onTrabalhaRadioChange, onTrabalhoRendaChange, onCursoAnoChange } = this;

    return (

        <LayoutSite>

            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Assistência Estudantil</Breadcrumb.Item>
              <Breadcrumb.Item>Inscrição atual</Breadcrumb.Item>
              <Breadcrumb.Item>1. Dados pessoais</Breadcrumb.Item>

            </Breadcrumb>

            <Layout className="site-layout-background"
              style={{
                marginTop: "24px",
                padding: '12px 0' }}>
                  <Content
                    style={{ padding: '0 12px', minHeight: 280 }}>
                      <Space
                        direction="vertical"
                        style={{width: "100%"}}>
                          <Button
                            type="link"
                            onClick={()=>this.props.onVoltarClick()}
                            style={{padding: "0"}}>Voltar</Button>
                          <Title level={4}>Dados pessoais</Title>

                          <div>
                            <Space
                              direction="vertical"
                              style={{width: "100%"}}>
                              <Text>Verifique se os dados de nome, sexo e endereço estão corretos. Caso estejam incorretos, procure a secretaria do seu Campus para atualização dos seus dados cadastrais no SUAP. Revise e atualize os demais campos, caso necessário.</Text>
                              <Space
                                direction="horizontal">
                                  <div>
                                      <Text style={{display: "block"}}>Nome:</Text>
                                      <Text strong>{aluno.nome}</Text>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>Data Nascimento:</Text>
                                      <Text strong>{aluno.data_nascimento}</Text>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>Sexo:</Text>
                                      <Text strong>{aluno.sexo}</Text>
                                  </div>
                              </Space>

                              <Text underline>Endereço</Text>

                              <Space
                                direction="horizontal">
                                  <div>
                                      <Text style={{display: "block"}}>CEP:</Text>
                                      <Text strong>{aluno.endereco.cep}</Text>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>Logradouro:</Text>
                                      <Text strong>{aluno.endereco.logradouro}</Text>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>Número:</Text>
                                      <Text strong>{aluno.endereco.numero}</Text>
                                  </div>
                              </Space>
                              <Space
                                direction="horizontal">
                                  <div>
                                      <Text style={{display: "block"}}>Bairro:</Text>
                                      <Text strong>{aluno.endereco.bairro}</Text>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>Cidade:</Text>
                                      <Text strong>{aluno.endereco.cidade}</Text>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>UF:</Text>
                                      <Text strong>{aluno.endereco.uf}</Text>
                                  </div>
                              </Space>

                              <Text>* Localização:</Text>
                              <Radio.Group
                                onChange={onLocalizacaoChange}
                                value={endereco_localizacao}>
                                <Radio value={0}>Urbano</Radio>
                                <Radio value={1}>Rural</Radio>
                              </Radio.Group>

                              <Text underline>Curso</Text>

                              {(windowWidth < 640)? (
                                <Space
                                  direction="vertical">
                                    <Space
                                      direction="horizontal">
                                        <div style={{width: "100%"}}>
                                          <Text style={{display: "block"}}>Curso:</Text>
                                          <Text strong>{aluno.curso.nome}</Text>
                                        </div>
                                    </Space>
                                    <Space
                                      direction="horizontal">
                                      <div>
                                        <Text style={{display: "block"}}>* Ano de ingresso:</Text>
                                        <Input
                                          value={curso_ano}
                                          maxLength={4}
                                          onChange={onCursoAnoChange}
                                          placeholder="Ex. 2019"/>
                                      </div>
                                    </Space>
                                </Space>
                              ):(
                                <Space
                                  direction="horizontal">
                                  <div>
                                    <Text style={{display: "block"}}>Curso:</Text>
                                    <Text strong>{aluno.curso.nome}</Text>
                                  </div>
                                  <div>
                                    <Text style={{display: "block"}}>* Ano de ingresso:</Text>
                                    <Input
                                      value={curso_ano}
                                      maxLength={4}
                                      onChange={onCursoAnoChange}
                                      placeholder="Ex. 2019"/>
                                  </div>
                                </Space>
                              )}

                              <Text underline>Renda</Text>

                              <Text>* Recebe alguma renda no momento?</Text>
                              <Radio.Group
                                onChange={onTrabalhaRadioChange}
                                value={trabalha}>
                                <Radio value="N">Não</Radio>
                                <Radio value="S">Sim</Radio>
                              </Radio.Group>

                              {(trabalha === "S") && (
                                <Space
                                  direction="horizontal">
                                    <div>
                                      <Text style={{display: "block"}}>* Descrição da(s) renda(s):</Text>
                                      <Input
                                        value={trabalho_descricao}
                                        maxLength={30}
                                        onChange={(e)=>this.setState({trabalho_descricao: e.target.value})} />
                                    </div>
                                    <div>
                                      <Text style={{display: "block"}}>* Renda mensal:</Text>
                                      <Input
                                        value={trabalho_remuneracao}
                                        onChange={onTrabalhoRendaChange} />
                                    </div>
                                </Space>
                              )}

                              <Text type="danger">Observação: Contabilize como renda valores proveniente de trabalho/atividade que desenvolve, aposentadoria e/ou pensão do INSS, pensão alimentícia e renda de aluguel de
                              imóvel. BPC, Bolsa Família, ajuda de terceiros e seguro desemprego não são contabilizados como renda. A soma dessas rendas deverá ser informada corretamente no questionário socioeconômico e incluída as comprovações nos anexos.</Text>

                              <Text underline>Dados bancários</Text>

                              <Text>* Tipo da conta</Text>
                              <Radio.Group
                                onChange={onContaTipo}
                                value={conta_tipo}>
                                <Radio value={-1}>Não possuo no momento</Radio>
                                <Radio value={0}>Poupança</Radio>
                                <Radio value={1}>Corrente</Radio>
                              </Radio.Group>

                              <Space
                                direction="horizontal">
                                  <div>
                                      <Text style={{display: "block"}}>Banco:</Text>
                                      <Input
                                        value={conta_banco}
                                        maxLength={40}
                                        onChange={(e)=>this.setState({conta_banco: e.target.value})}
                                        placeholder="Ex. Caixa"/>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>Agência:</Text>
                                      <Input
                                        value={conta_agencia}
                                        maxLength={10}
                                        onChange={(e)=>this.setState({conta_agencia: e.target.value})}
                                        placeholder="Ex. 100-1"/>
                                  </div>
                                  <div>
                                      <Text style={{display: "block"}}>Número da conta:</Text>
                                      <Input
                                        value={conta_numero}
                                        maxLength={20}
                                        onChange={(e)=>this.setState({conta_numero: e.target.value})}
                                        placeholder="Ex. 213234-5"/>
                                  </div>
                              </Space>

                              <Text underline>Contato</Text>

                              <Space
                                  direction="horizontal">
                                  <div>
                                    <Text style={{display: "block"}}>E-mail:</Text>
                                    <Text strong>{aluno.email}</Text>
                                  </div>
                                  <div>
                                    <Text style={{display: "block"}}>* Celular:</Text>
                                    <InputMask
                                      className="ant-input"
                                      mask="\(99\) \99999\-9999"
                                      value={contato_celular}
                                      onChange={e=>this.setState({contato_celular: e.target.value})}
                                      />
                                  </div>
                              </Space>
                              {(messageError.length > 0) &&
                              <div className="alert-box">
                                <Text style={{display: "block"}}>Problemas encontrados:</Text>
                                {messageError.map((message, i)=>
                                    <Text key={i} style={{display: "block"}}>🔸 {message}</Text>
                                )}
                              </div>
                              }

                              <Space
                                direction="horizontal"
                                style={{marginTop: "15px"}}>
                                <Button
                                  onClick={()=>this.props.onVoltarClick()}>Voltar</Button>
                                {processando ? (
                                  <Button
                                    type="primary" loading>Aguarde ...</Button>
                                ):(
                                  <Button
                                    onClick={salvarAlteracoes}
                                    type="primary">Salvar</Button>
                                )}

                              </Space>
                            </Space>

                          </div>

                        <div
                          style={{width: "100%", minHeight: "30px"}}>

                        </div>
                      </Space>

                  </Content>
            </Layout>

        </LayoutSite>
    );
  }
}

export default windowSize(DadosPessoais);